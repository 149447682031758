<template>
  <div>
    <div class="flex flex-wrap">
      <div class="w-full sm:w-1/2 sm:pr-1">
        <ValidationProvider v-slot="{ errors }" name="customer_first_name" rules="required">
          <base-input-field
            v-model="first_name"
            :label="'Omistajan etunimi'"
            :errors="errors"
            :disabled="editDisabled"
          ></base-input-field>
        </ValidationProvider>
      </div>
      <div class="w-full sm:w-1/2 sm:pl-1">
        <ValidationProvider v-slot="{ errors }" name="customer_last_name" rules="required">
          <base-input-field
            v-model="last_name"
            :label="'Omistajan sukunimi'"
            :errors="errors"
            :disabled="editDisabled"
          ></base-input-field>
        </ValidationProvider>
      </div>
    </div>
    <div class="w-full sm:w-2/3">
      <ValidationProvider 
        v-slot="{ errors }" 
        name="customer_email" 
        vid="customer_email"
        rules="email"
      >
        <base-input-field
          v-model="email"
          :label="'Sähköposti'"
          :errors="errors"
          :disabled="editDisabled"
        ></base-input-field>
      </ValidationProvider>
    </div>
    <div class="w-full sm:w-2/3">
      <ValidationProvider 
        v-slot="{ errors }" 
        name="customer_phone" 
        vid="customer_phone"
      >
        <base-input-field
          v-model="phone"
          :label="'Puhelin'"
          :errors="errors"
          :disabled="editDisabled"
        ></base-input-field>
      </ValidationProvider>
    </div>
    <div class="flex flex-wrap">
      <div class="w-full sm:w-1/2 md:w-1/3 md:pr-1">
        <ValidationProvider v-slot="{ errors }" name="street_address">
          <base-input-field
            v-model="street_address"
            :label="'Katuosoite'"
            :errors="errors"
            :disabled="editDisabled"
          ></base-input-field>
        </ValidationProvider>
      </div>
      <div class="w-full sm:w-1/2 md:w-1/3 md:px-1">
        <ValidationProvider v-slot="{ errors }" name="zip_code">
          <base-input-field
            v-model="zip_code"
            :label="'Postinumero'"
            :errors="errors"
            :disabled="editDisabled"
          ></base-input-field>
        </ValidationProvider>
      </div>
      <div class="w-full sm:w-1/2 md:w-1/3 md:pl-1">
        <ValidationProvider v-slot="{ errors }" name="city">
          <base-input-field
            v-model="city"
            :label="'Kaupunki'"
            :errors="errors"
            :disabled="editDisabled"
          ></base-input-field>
        </ValidationProvider>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CustomerForm',
  props: {
    value: Object,
    editDisabled: {
      type: Boolean,
      default: true
    } 
  },
  computed: {
    first_name: {
      get () {
        return this.value && this.value.first_name
          ? this.value.first_name
          : ''
      },
      set (first_name) {
        this.edit({
          ...this.value,
          first_name: first_name
        })
      } 
    },
    last_name: {
      get () {
        return this.value && this.value.last_name
          ? this.value.last_name
          : ''
      },
      set (last_name) {
        this.edit({
          ...this.value,
          last_name: last_name
        })
      } 
    },
    phone: {
      get () {
        return this.value && this.value.phone
          ? this.value.phone
          : ''
      },
      set (phone) {
        this.edit({
          ...this.value,
          phone: phone
        })
      } 
    },
    email: {
      get () {
        return this.value && this.value.email
          ? this.value.email
          : ''
      },
      set (email) {
        this.edit({
          ...this.value,
          email: email != "" ? email : null
        })
      } 
    },
    street_address: {
      get () {
        return this.value && this.value.street_address
          ? this.value.street_address
          : ''
      },
      set (street_address) {
        this.edit({
          ...this.value,
          street_address: street_address
        })
      } 
    },
    zip_code: {
      get () {
        return this.value && this.value.zip_code
          ? this.value.zip_code
          : ''
      },
      set (zip_code) {
        this.edit({
          ...this.value,
          zip_code: zip_code
        })
      } 
    },
    city: {
      get () {
        return this.value && this.value.city
          ? this.value.city
          : ''
      },
      set (city) {
        this.edit({
          ...this.value,
          city: city
        })
      } 
    },
  },
  methods: {
    edit (payload) {
      this.$emit('input', payload)
    },
  }
}
</script>

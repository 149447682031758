import { loaderMutations } from '@/graphql/local/queries/loader'
import { notificationMutations } from '@/graphql/local/queries/notification'
import { workOrderMutations } from '@/graphql/local/queries/work-order'

export default {
  Mutation: {
		...loaderMutations,
		...notificationMutations,
		...workOrderMutations
	},
	Extinguisher: {
		isNew: () => {
			return false
		},
		price: () => {
			return 0
		}
	}
};

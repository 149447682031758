import moment from 'moment'

export const timeMixins = {
	methods: {
		formatDate (time) {
			const m = moment(time)
			return m.format('HH:mm D/M/YYYY')
		},
		formatDay (time) {
			const m = moment(time)
			return m.format('D/M/YYYY')
		},
		formatDayPoints (time) {
			const m = moment(time)
			return m.format('D.M.YYYY')
		},
		toDate (time) {
			const m = moment(time)
			return m.format('YYYY-MM-DD')
		}
	}
}



<template>
  <div>
    <div class="flex justify-center">
      <div class="w-64">
        <base-search-field
          v-model="searchWord"
          :placeholder="'Hae sammutinta...'"
        ></base-search-field>
      </div>
    </div>
    <div class="relative mt-4">
      <base-list-card
        v-for="(cardData, index) in extinguisherListData"
        :key="index"
        :cardData="cardData"
        @edit="edit"
      ></base-list-card>
      <div 
        class="flex justify-center mt-4">
        <button 
          v-if="hasMore"
          class="btn"
          @click="getMore"
        >Lataa lisää</button>
        <span v-else>Ei enempää sammuttimia rajauksilla</span>
      </div>
    </div>
  </div>
</template>
<script>
import BaseListCard from '@/components/base/BaseListCard'
import {timeMixins} from '@/mixins/time-mixins'
import gql from 'graphql-tag'
import moment from 'moment'

export default {
  name: 'ExtinguisherList',
  components: {
    BaseListCard
  },
  mixins: [timeMixins],
  data () {
    return {
      search: '',
      endCursor: null,
      hasMore: false,
      variables: {
        conditions: null,
        ownerConditions: null,
        after: null
      },
    }
  },
  created () {
    this.$apollo.queries.extinguisherSearch.refetch()
  },
  apollo: {
    extinguisherSearch: {
      query: gql`query (
        $conditions: QueryExtinguisherSearchWhereWhereConditions
        $after: String
      ) {
        extinguisherSearch (
          first: 10
          where: $conditions 
          after: $after
        ) {
          edges {
            node {
              id
              weight
              model
              serial_number
              maintained
              maintenance_types
              maintenances {
                id
                created_at
                types
                updated_at
              }
              received
              isNew @client
              owner {
                id
                first_name
                last_name
                phone
                email
              }
              created_at
              updated_at
            }
            cursor
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
      }`,
      variables () {
        return this.variables
      },
      error (error) {
        console.log(error.message)
      },
      result ({data: {extinguisherSearch: {pageInfo}}}) {
        this.endCursor = pageInfo.endCursor
        this.hasMore = pageInfo.hasNextPage
      }
    } 
  },
  computed: {
    searchWord: {
      get () {
        return this.search
      },
      set (searchWord) {
        this.search = searchWord
        if (searchWord !== null && searchWord.length > 0) {
          let conditions = {
            'OR': [
              { column: 'MODEL', operator: 'LIKE', value: `%${searchWord}%`},
              { column: 'SERIAL_NUMBER', operator: 'LIKE', value: `%${searchWord}%` },   
            ]
          }
          let ownerConditions = {
            'OR': [
              { column: 'FIRST_NAME', operator: 'LIKE', value: `%${searchWord}%`},
              { column: 'LAST_NAME', operator: 'LIKE', value: `%${searchWord}%` },
              { column: 'EMAIL', operator: 'LIKE', value: `%${searchWord}%` }
            ]
          }
          this.variables = {
            conditions: conditions,
            ownerConditions: ownerConditions
          }
        } else {
          this.variables = {
            ...this.variables,
            conditions: null,
            ownerConditions: null
          }
        }
      } 
    },
    extinguisherListData () {
      if (this.extinguisherSearch && this.extinguisherSearch.edges) {
        const extinguishers = this.extinguisherSearch.edges.map(edge => edge.node)
        return extinguishers.map(item => ({
          id: item.id,
          title: {
            color: item.maintained ? 'text-green-600' : 'text-orange-600',
            text: item.maintained ? 'Huollettu' : 'Huollossa'
          },
          items: [
            {
              subtitle: 'Sarjanumero',
              value: item.serial_number
            },
            {
              subtitle: 'Omistaja',
              value: item.owner 
                ? `${item.owner.first_name} ${item.owner.last_name}` 
                : 'Ei lisätty' 
            },
            {
              subtitle: 'Huollettu viimeksi',
              value: this.getLatestMaintenance(item.maintenances) 
            },
          ]
        }))
      } else {
        return []
      }
    }
  },
  methods: {
    getLatestMaintenance (maintenances) {
      if (maintenances.length === 0) {
        return 'Ei huoltoja'
      }

      let latest = maintenances[0]
      maintenances.forEach((maintenance, index) => {
        if (index > 0) {
          if (moment(maintenance.updated_at).isBefore(moment(latest.updated_at))) {
            latest = maintenance
          }
        } 
      }) 

      return this.formatDayPoints(latest.updated_at)
    },
    getMore () {
      // Fetch more data and transform the original result
      this.$apollo.queries.extinguisherSearch.fetchMore({
        // New variables
        variables: {
          ...this.variables,
          after: this.endCursor 
        },
        // Transform the previous result with new data
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const newEdges = fetchMoreResult.extinguisherSearch.edges

          return {
            extinguisherSearch: {
              __typename: previousResult.extinguisherSearch.__typename,
              // Merging the tag list
              edges: [...previousResult.extinguisherSearch.edges, ...newEdges],
              pageInfo: fetchMoreResult.extinguisherSearch.pageInfo
            },
          }
        },
      })
    },
    edit (id) {
      this.$router.replace({path: `/sammutin/muokkaa/${id}/tiedot`})
    }
  }
}
</script>

<template>
  <base-validation-form ref="maintenanceForm">
    <div class="mt-6">
      <base-back-link @click="goToExtinguisherEdit">Huoltohistoria</base-back-link>
      <div v-if="$apollo.queries.maintenance.loading">Ladataan...</div>
      <div v-else-if="$apollo.queries.maintenance.error">An error occurred</div>
      <div 
        v-else
        :class="[fadeIn]"
      >
        <Maintenance-form
          v-model="maintenance"
          @save="updateMaintenance"
        ></Maintenance-form>
      </div>
    </div>
  </base-validation-form>
</template>
<script>
import MaintenanceForm from '@/components/MaintenanceForm'
import animationMixins from '@/mixins/animation-mixins'
import getMaintenance from '@/graphql/queries/Maintenance.gql'
import updateMaintenance from '@/graphql/mutations/UpdateMaintenance.gql'
import { stateMixins } from '@/mixins/state-mixins'
import _ from 'lodash'

export default {
  name: 'MaintenanceEditDataView',
  components: {
    MaintenanceForm
  },
  mixins: [animationMixins, stateMixins],
  data () {
    return {
      Maintenance: {}
    }
  },
  mounted () {
    this.animationDone = true
  },
  apollo: {
    maintenance: {
      query: getMaintenance,
      variables () {
        return {
          id: this.$route.params.maintenance_id
        }
      },
      error (error) {
        console.log(error.message)
      },
      result ({ data: {maintenance} }) {
        this.maintenance = maintenance 
      }
    } 
  },
  methods: {
    async updateMaintenance () {
      const valid = await this.$refs['maintenanceForm'].validate()
      if (valid) {
        this.toggleLoader(true)
        try {
            let maintenanceData = _.cloneDeep(this.maintenance)
            if (Object.prototype.hasOwnProperty.call(maintenanceData, '__typename')) {
              delete maintenanceData['__typename']
            }
            if (
              Object.prototype.hasOwnProperty.call(maintenanceData, 'owner')
              && Object.prototype.hasOwnProperty.call(maintenanceData.owner, '__typename')
            ) {
              delete maintenanceData.owner['__typename']
            }
          await this.$apollo.mutate({
              mutation: updateMaintenance,
              variables: maintenanceData,
              update: (store, { data: { updateMaintenance } }) => {
                // Read the data from our cache for this query.
                const data = store.readQuery({ 
                  query: getMaintenance,
                  variables: {id: this.$route.params.maintenance_id}
                })
                data.maintenance = updateMaintenance
                // Write our data back to the cache.
                store.writeQuery({ query: getMaintenance, data })
              },
            }) 
            this.setNotification({
              text: 'Huollon tiedot päivitettiin',
              type: 'success',
            })
        } catch (e) {
            this.setNotification({
              text: `Epäonnistui (${e.message})`,
              type: 'error',
            })
        } finally {
          this.toggleLoader(false)
        }
      } else {
        this.setNotification({
          text: 'Tarkista kentät',
          type: 'success',
        })
      }
    },
    goToExtinguisherEdit () {
      this.$router.replace({path: `/sammutin/muokkaa/${this.$route.params.extinguisher_id}/huollot`})
    }
  }
}
</script>

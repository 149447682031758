<template>
  <div 
    v-if="showLoader"
    class="fixed w-64 mt-3 mr-3 top-0 left-0 max-w-sm rounded-lg overflow-hidden shadow-lg">
  </div>
</template>
<script>
import { loaderQuery } from '@/graphql/local/queries/loader'

export default {
  name: 'Loader',
  apollo: {
    showLoader: {
      query: loaderQuery
    }
  },
}
</script>

<template>
  <div>
   <label class="block text-sm font-medium leading-5 text-gray-700 mb-1">
     {{label}}
  </label>
    <div class="flex w-full">
      <date-picker 
        :first-day-of-week="2" 
        v-model="date" 
        class="flex-grow"
        locale="fi"
      >
        <template v-slot="{ inputValue, inputEvents }">
          <input
            :id="label"
            class="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3"
            :value="inputValue"
            v-on="inputEvents"
          >
        </template>
      </date-picker>
    </div>
    <p 
      v-if="errors.length > 0"
      class="text-red-400 text-xs italic">{{errors[0]}}
    </p>
  </div>
</template>
<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import { timeMixins } from '@/mixins/time-mixins'

export default {
  name: 'BaseDatePicker',
  components: {
    DatePicker
  },
  mixins: [timeMixins],
  props: {
    value: {
      required: true,
      default: ''
    },
    label: {
      type: String,
    },
    errors: {
      type: [String, Array],
      default: () => [] 
    }
  },
  computed: {
    date: {
      get () {
        return this.value
      },
      set (date) {
        this.$emit('input', this.toDate(date))
      } 
    }
  }
}
</script>


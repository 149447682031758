var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"flex flex-wrap"},[_c('div',{staticClass:"w-full sm:w-1/2 sm:pr-1"},[_c('ValidationProvider',{attrs:{"name":"customer_first_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('base-input-field',{attrs:{"label":'Omistajan etunimi',"errors":errors,"disabled":_vm.editDisabled},model:{value:(_vm.first_name),callback:function ($$v) {_vm.first_name=$$v},expression:"first_name"}})]}}])})],1),_c('div',{staticClass:"w-full sm:w-1/2 sm:pl-1"},[_c('ValidationProvider',{attrs:{"name":"customer_last_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('base-input-field',{attrs:{"label":'Omistajan sukunimi',"errors":errors,"disabled":_vm.editDisabled},model:{value:(_vm.last_name),callback:function ($$v) {_vm.last_name=$$v},expression:"last_name"}})]}}])})],1)]),_c('div',{staticClass:"w-full sm:w-2/3"},[_c('ValidationProvider',{attrs:{"name":"customer_email","vid":"customer_email","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('base-input-field',{attrs:{"label":'Sähköposti',"errors":errors,"disabled":_vm.editDisabled},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}])})],1),_c('div',{staticClass:"w-full sm:w-2/3"},[_c('ValidationProvider',{attrs:{"name":"customer_phone","vid":"customer_phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('base-input-field',{attrs:{"label":'Puhelin',"errors":errors,"disabled":_vm.editDisabled},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})]}}])})],1),_c('div',{staticClass:"flex flex-wrap"},[_c('div',{staticClass:"w-full sm:w-1/2 md:w-1/3 md:pr-1"},[_c('ValidationProvider',{attrs:{"name":"street_address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('base-input-field',{attrs:{"label":'Katuosoite',"errors":errors,"disabled":_vm.editDisabled},model:{value:(_vm.street_address),callback:function ($$v) {_vm.street_address=$$v},expression:"street_address"}})]}}])})],1),_c('div',{staticClass:"w-full sm:w-1/2 md:w-1/3 md:px-1"},[_c('ValidationProvider',{attrs:{"name":"zip_code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('base-input-field',{attrs:{"label":'Postinumero',"errors":errors,"disabled":_vm.editDisabled},model:{value:(_vm.zip_code),callback:function ($$v) {_vm.zip_code=$$v},expression:"zip_code"}})]}}])})],1),_c('div',{staticClass:"w-full sm:w-1/2 md:w-1/3 md:pl-1"},[_c('ValidationProvider',{attrs:{"name":"city"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('base-input-field',{attrs:{"label":'Kaupunki',"errors":errors,"disabled":_vm.editDisabled},model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}})]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }
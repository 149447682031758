<template>
  <div>
    <h3 class="text-lg font-medium leading-6 text-gray-900 mb-4">Päiväykset</h3>
    <div class="flex">
      <div class="w-1/2 pr-1">
        <base-date-picker
          v-model="received"
          :label="'Otettu vastaan'"
        ></base-date-picker>
      </div>
      <div class="w-1/2 pl-1">
        <base-date-picker
          v-model="returnDate"
          :label="'Luovutettavissa'"
        ></base-date-picker>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'DatesData',
  props: {
    value: Object
  },
  computed: {
    received: {
      get () {
        return this.value.received
      },
      set (received) {
        this.edit({
          received: received
        })
      } 
    },
    returnDate: {
      get () {
        return this.value.returnDate
      },
      set (returnDate) {
        this.edit({
          returnDate: returnDate 
        })
      } 
    }
  },
  methods: {
    edit (payload) {
      this.$emit('input', payload)
    }
  }
}
</script>

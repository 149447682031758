<template>
  <div v-if="value">
    <div class="fixed inset-0 opacity-50 bg-black z-40"></div>
    <div 
      class="modal-dialog bg-white"
      :class="[
        {'modal-dialog-min-height': hasMinHeight}
      ]"
      :style="{maxWidth: maxWidth}"
    >
      <div class="w-full bg-gray-900 flex justify-between items-center px-2 py-4">
        <div class="text-white font-bold pl-2">{{header}}</div>
        <span 
          class="cursor-pointer"
          @click="close"
        >
       <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="" viewBox="0 0 24 24" stroke="#fff">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
      </svg> 
        </span>
      </div>
      <div class="px-4 py-4 overflow-y-auto">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BaseModal',
  props: {
    header: {
      type: String,
      default: ''
    },
    value: {
      type: Boolean,
    },
    hasMinHeight: {
      type: Boolean,
      default: false
    },
    maxWidth: String
  },
  methods: {
    close () {
      this.$emit('input', false)
    }
  }
}
</script>
<style lang="scss" scoped>
.modal-dialog {
  width:  95%;
  position: fixed;
  border-radius: 4px;
  top: 50%;
  left: 50%;
  max-height: 90%;
  overflow-y: auto;
  transform: translate(-50%, -50%);
  transition: .3s cubic-bezier(.25,.8,.25,1);
  z-index: 49;
  box-shadow: 0 11px 15px -7px rgba(0,0,0,.2), 0 24px 38px 3px rgba(0,0,0,.14), 0 9px 46px 8px rgba(0,0,0,.12);

  &-min-height {
    min-height: 400px;
  }
}
</style>


import moment from 'moment'

export default {
	data: {
		showLoader: false,
		notification: {
			__typename: 'Notification',
			text: '',
			type: '',
			show: false
		},
		//TODO remove example
		todoItems: [
			{
				__typename: 'Item',
				id: 'dqdBHJGgjgjg',
				text: 'test',
				done: true,
			},
		],
		workOrder: {
			__typename: 'WorkOrder',
			received: moment().format('YYYY-MM-DD'), 
			returnDate: moment().add(1, 'days').format('YYYY-MM-DD'), 
			customer: {
				__typename: 'Customer',
				id: null,
				first_name: '',
				last_name: '',
				phone: '',
				email: '',
				street_address: null,
				zip_code: null,
				city: null
			},
			extinguishers: []
		}
	},
}


<template>
  <div 
    class="bg-white shadow-md rounded px-4 sm:px-8 pt-6 pb-8 mb-4"
  >
    <h3 class="text-lg font-medium leading-6 text-gray-900 mb-4">Huollon tiedot</h3>
    <div class="flex flex-wrap">
      <div class="w-full sm:w-1/2 sm:pr-1">
        <ValidationProvider v-slot="{ errors }" name="received" rules="required">
          <base-date-picker
            v-model="received"
            :label="'Otettu vastaan'"
            :errors="errors"
          ></base-date-picker>
        </ValidationProvider>
      </div>
      <div class="w-full sm:w-1/2 sm:pl-1">
        <ValidationProvider v-slot="{ errors }" name="date_completed" rules="required">
          <base-date-picker
            v-model="date_completed"
            :label="'Huolto tehty'"
            :errosr="errors"
          ></base-date-picker>
        </ValidationProvider>
      </div>
      <div class="w-full">
       <label class="block text-sm font-medium leading-5 text-gray-700 mb-1 mt-2">
         Tehdyt toimenpiteet
      </label>
      </div>
      <maintenance-type
        :chosen="types" 
        @toggleChip="toggleChip"
      ></maintenance-type>
    </div>
    <base-divider></base-divider>
    <h3 class="text-lg font-medium leading-6 text-gray-900 mb-4">Huollon tekijän tiedot</h3>
      <base-select
        v-model="maintainer_id"
        :options="maintainersList"
        :optionText="'name'"
        :optionValue="'id'"
      ></base-select>
      <!--
    <div class="flex flex-wrap">
      <div class="w-full sm:w-1/2 sm:pr-1">
        <base-input-field
          v-model="first_name"
          :label="'Huollon tekijän etunimi'"
        ></base-input-field>
      </div>
      <div class="w-full sm:w-1/2 sm:pl-1">
        <base-input-field
          v-model="last_name"
          :label="'Huollon tekijän sukunimi'"
        ></base-input-field>
      </div>
    </div>
    -->
    <div class="flex justify-end mt-10">
      <button 
        class="btn btn-tertiary"
        @click="$emit('save')"
      >{{text}}</button>
    </div>
  </div>
</template>
<script>
import MaintenanceType from '@/components/MaintenanceType'
import gql from 'graphql-tag'

export default {
  name: 'MaintenanceForm',
  components: {
    MaintenanceType
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    create: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      tempMaintainers: [
        'Teppo',
        'Seppo'
      ]
    }
  },
  apollo: {
    maintainers: {
      query: gql`query maintainers {
        maintainers {
          id
          first_name
          last_name
        }
      }`,
      error (error) {
        console.log(error.message)
      },
    } 
  },
  computed: {
    maintainersList () {
      if (this.maintainers) {
        let maintainers = [...this.maintainers]
        maintainers = maintainers.map(maintainer => ({
          id: maintainer.id,
          name: `${maintainer.first_name} ${maintainer.last_name}`,
        }))
        return maintainers
      } else {
        return []
      }
    },
    text () {
      return this.create
        ? 'Merkitse huolto valmiiksi'
        : 'Tallenna muutokset'
    },
    maintainer_id: {
      get () {
        return this.value.maintainer_id
          ? this.value.maintainer_id
          : ''
      },
      set (maintainer_id) {
        this.edit({
          ...this.value,
          maintainer_id: maintainer_id 
        })
      } 
    },
    received: {
      get () {
        return this.value.received
          ? this.value.received
          : ''
      },
      set (received) {
        this.edit({
          ...this.value,
          received: received
        })
      } 
    },
    date_completed: {
      get () {
        return this.value.date_completed
          ? this.value.date_completed
          : ''
      },
      set (date_completed) {
        this.edit({
          ...this.value,
          date_completed: date_completed
        })
      } 
    },
    types: {
      get () {
        return this.value.types
          ? this.value.types
          : ''
      },
      set (types) {
        this.edit({
          ...this.value,
          types: types
        })
      } 
    }
  },
  methods: {
    toggleChip (value) {
      this.types = value
    },
    edit (payload) {
      this.$emit('input', {
        ...this.value,
        ...payload
      })
    }
  }
}
</script>

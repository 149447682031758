import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './sass/main.scss'
import globalComponents from './global-components.js'
import 'animate.css'
import { createProvider } from './vue-apollo'
import resolvers from '@/graphql/local/resolvers'
import cacheInit from '@/graphql/local'
import { typeDefs } from '@/graphql/local/typeDefs'
import { ValidationProvider } from 'vee-validate'

globalComponents.forEach(component => {
	Vue.component(component.name, component)
})

Vue.component('ValidationProvider', ValidationProvider)

Vue.config.productionTip = false

const apolloOptions = {
	typeDefs,
	resolvers,
	onCacheInit: cache => {
		cache.writeData(cacheInit)
	}
}

new Vue({
  router,
  store,
  apolloProvider: createProvider(apolloOptions),
  render: h => h(App)
}).$mount('#app')



<template>
  <div v-if="notification.show" class="fixed top-0 right-0 pt-2 pr-2 max-w-md z-50">
    <div 
      class="border pl-4 pr-12 py-3 rounded relative mb-2 shadow-lg"
      :class="[
        {'notification-success': notification.type === 'success'},
        {'notification-error': notification.type === 'error'},
        {'notification-warning': notification.type === 'warning'}
      ]"
    >
      <div class="flex">
        <strong 
          v-if="false"
          class="font-bold">Tapahtui virhe!
        </strong>
        <span 
          v-if="notification.type === 'success'"
          class="mr-2"
        >
          <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#2ecc71">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        </span>
        <span class="block sm:inline">{{notification.text}}</span>
        <span 
          class="absolute top-0 bottom-0 right-0 px-4 py-3"
          @click="hideNotification"
        >
          <svg 
            class="fill-current h-6 w-6" 
            role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { notificationQuery } from '@/graphql/local/queries/notification'
import { stateMixins } from '@/mixins/state-mixins'

export default {
  name: 'Notification',
  mixins: [stateMixins],
  apollo: {
    notification: {
      query: notificationQuery 
    }
  },
}
</script>
<style lang="scss" scoped>
.notification-success {
  @apply bg-green-200 border-green-400;
}

.notification-error {
  @apply bg-red-100 border-red-400 text-red-700;
}

.notification-warning {
  @apply bg-yellow-100 border-yellow-400 text-yellow-700;
}
</style>


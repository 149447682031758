<template>
  <base-validation-form ref="extinguisherForm">
    <div 
      class="bg-white shadow-md rounded px-4 sm:px-8 pt-6 pb-8 mb-4"
    >
      <h3 class="text-lg font-medium leading-6 text-gray-900 mb-4">Sammuttimen tiedot</h3>
      <extinguisher-data
        v-model="extinguisher"
      ></extinguisher-data>
      <base-divider></base-divider>
      <h3 class="text-lg font-medium leading-6 text-gray-900 mb-4">Omistajan tiedot</h3>
      <customer-data 
        v-model="customer"
        :hasBeenAdded="extinguisher.customer_id !== null"
        :showUpdateButton="false"
      >
      </customer-data>
      <div class="flex justify-end">
        <button class="btn" @click="$emit('save')">Tallenna</button>
      </div>
    </div>
  </base-validation-form>
</template>
<script>
import ExtinguisherData from '@/components/ExtinguisherData'
import CustomerData from '@/components/CustomerData'

export default {
  name: 'ExtinguisherForm',
  components: {
    ExtinguisherData,
    CustomerData
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    maintenance: Object
  },
  computed: {
    customer: {
      /**
       * Getter method for the customer
       * @return {Object} 
       */
      get () {
        return this.value.owner
      },
    
      /**
       * Setter method for the customer 
       * @param {Object} customer 
       */
      set (customer) {
        const customerData = {...customer}
        if (Object.prototype.hasOwnProperty.call(customerData, '__typename')) {
          delete customerData['__typename']
        }
        this.edit({
          ...this.value,
          customer_id: customer.id,
          owner: {
            ...this.value.owner,
            ...customerData
          }
        })
      } 
    },
    extinguisher: {
      /**
       * Getter method for the extinguisher
       * @return {Object} 
       */
      get () {
        return this.value
      },
    
      /**
       * Setter method for the extinguisher 
       * @param {Object} extinguisher 
       */
      set (extinguisher) {
        this.edit(extinguisher)
      } 
    },
  },
  methods: {
    async validate () {
      const valid = await this.$refs['extinguisherForm'].validate()
      return valid
    },
    edit (payload) {
      this.$emit('input', {
        ...this.value,
        ...payload
      })
    }
  }
}
</script>

<template>
  <div>
    <label 
      v-if="!noLabel"
      class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
     {{label}}
    </label>
    <div class="relative">
      <select 
        v-model="selected"
        class="appearance-none w-full border text-grey-darker py-3 px-4 pr-8 rounded" 
        :class="[
          {'bg-white': bgWhite},
          {'bg-gray-200': !bgWhite},
        ]"
      >
        <option 
          v-for="(option, index) in options"
          :key="index"
          v-html="optionText.length > 0 && optionValue.length > 0 ? option[optionText] : option"
          :value="optionText.length > 0 && optionValue.length > 0 ? option[optionValue] : option"
        >
        </option>
      </select>
      <div class="select-arrow pointer-events-none absolute top-1/2 right-0 flex items-center px-2 text-grey-darker">
        <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
      </div>
  </div>
</div>
</template>
<script>
export default {
  name: 'BaseSelect',
  props: {
    value: {
      required: true,
      type: [String, Number, Boolean]
    },
    label: {
      type: String,
      default: 'Valinta'
    },
    noLabel: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      required: true
    },
    optionText: {
      type: String,
      default: ''
    },
    optionValue: {
      type: String,
      default: ''
    },
    bgWhite: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      open: false
    }
  },
  computed: {
    selected: {
      get () {
        return this.value
      },
      set (selected) {
        this.$emit('input', selected)
      } 
    }
  }
}
</script>
<style scoped lang="scss">
.select-arrow {
  transform: translateY(-50%);
}
</style>


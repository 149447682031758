export const commonMixins = {
	methods: {
		getValidationErrors ({graphQLErrors, networkError}) {
			if (graphQLErrors) {
				const messages = graphQLErrors.map(({ message, locations, path, extensions }) => {
					if (
						extensions
						&& Object.prototype.hasOwnProperty.call(extensions, 'validation')
					) {
							const first = Object.values(extensions.validation)[0]
							return first[0]	
					} else {
						return `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}` 
					}
				})
				return messages[0]
			} 
			if (networkError) return `[Network error]: ${networkError}`
		}
	}
}

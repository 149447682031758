<template>
  <div id="app">
    <div class="container mx-auto">
      <div 
        v-if="showHeaderLinks"
        class="flex justify-between"
      >
        <span 
          class="block mt-2 cursor-pointer"
          @click="goToHome"
        >
          <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
          </svg>
        </span>
        <span 
          class="block mt-2 cursor-pointer"
          @click="logout"
        >
          <svg class="w-5 h5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
          </svg>
        </span>
      </div>
      <div class="flex justify-center pt-3">
        <img src="@/assets/slnshlogo.png" alt=""/>
      </div>
    <div class="flex justify-center mt-4 mb-3">
      <h2 class="text-xl text-center text-gray-700">{{routeTitle}}</h2>
    </div>
      <router-view/>
    </div>
    <notification></notification>
    <loader></loader>
  </div>
</template>
<script>
import Notification from '@/components/Notification'
import Loader from '@/components/Loader'
import { stateMixins } from '@/mixins/state-mixins'
import { onLogout } from '@/vue-apollo.js'
import Storage from '@/lib/Storage'

  export default {
    name: 'App',
    components: {
      Notification,
      Loader
    },
    mixins: [stateMixins],
    computed: {
      routeTitle () {
        return this.$route.meta.title
      },
      showHeaderLinks () {
        const currentRoute = this.$route
        return currentRoute.name !== 'login'
      }
    },
    methods: {
      goToHome () {
        this.$router.replace({path: '/'})
      },
      logout () {
        onLogout(this.$apollo.provider.defaultClient)
        Storage.setItem('isLoggedIn', false)
        this.$router.replace({path: '/kirjaudu'})
      }
    }
  }
</script>

<template>
  <div 
    class="flex justify-center items-center m-1 font-medium py-2 px-2 rounded-full border border-orange-600 cursor-pointer"
    :class="[
      {'text-orange-600': !isChosen},
      {'bg-orange-100': !isChosen},
      {'text-current': isChosen},
      {'bg-orange-500': isChosen}
    ]"
    @click="$emit('click')"
  >
    <div 
      class="text font-normal leading-none max-w-full flex-initial"
    ><slot /></div>
  </div>
</template>
<script>
export default {
  name: 'BaseChip',
  props: {
    isChosen: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<template>
  <div class="mt-12">
    <div
      :class="[fadeIn]"
    >
      <extinguisher-form 
        v-model="extinguisher"
        @save="createExtinguisher"
        ref="extinguisherCreateForm"
      ></extinguisher-form>
    </div>
  </div>
</template>
<script>
import ExtinguisherForm from '@/components/ExtinguisherForm'
import animationMixins from '@/mixins/animation-mixins'
import createExtinguisher from '@/graphql/mutations/CreateExtinguisher.gql'
import { stateMixins } from '@/mixins/state-mixins'
import { commonMixins } from '@/mixins/common-mixins'

export default {
  name: 'ExtinguisherCreateView',
  components: {
    ExtinguisherForm
  },
  mixins: [animationMixins, stateMixins, commonMixins],
  data () {
    return {
      extinguisher: {
        weight: 2,
        serial_number: '',
        model: '',
        customer_id: null,
        owner: null,
      }
    }
  },
  methods: {
    async createExtinguisher () {
      const valid = await this.$refs['extinguisherCreateForm'].validate()
      if (
        Object.prototype.hasOwnProperty.call(this.extinguisher, 'owner')
        && this.extinguisher.owner !== null
        && (this.extinguisher.owner.phone === null || this.extinguisher.owner.phone === '')
        && (this.extinguisher.owner.email === null || this.extinguisher.owner.email === '')
      ) {
        this.setNotification({
          text: 'Omistajan sähköposti tai puhelinnumero tulee olla syötetty',
          type: 'warning',
        })
        return
      } 
      if (!valid) {
        this.setNotification({
          text: 'Tarkista kentät',
          type: 'warning',
        })
      } else {
        this.toggleLoader(true)
        try {
          const result = await this.$apollo.mutate({
            mutation: createExtinguisher,
            variables: this.extinguisher,
          })    
          this.setNotification({
            text: 'Sammuttin lisättiin',
            type: 'success',
          })
          this.$router.replace({path: `/sammutin/muokkaa/${result.data.createExtinguisher.id}`})
        } catch (e) {
          const notificationError = this.getValidationErrors(e)
          this.setNotification({
            text: `Epäonnistui: ${notificationError}`,
            type: 'error',
          })
        } finally {
          this.toggleLoader(false)
        }
      }
    }
  },
  mounted () {
    this.animationDone = true
  }
}
</script>

import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import Storage from '@/lib/Storage'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
	if (to.matched.some(record => record.meta.requiresAuth)) {
		if (!Storage.hasItem('slnshapp')) {
			next({
				path: '/kirjaudu',
			})
		} else {
			const storageData = Storage.getItem('slnshapp')
			if (!storageData.isLoggedIn) {
				next({
					path: '/kirjaudu',
				})
			} else {
				next()
			}
		}
	} else {
		next() // make sure to always call next()!
	}
})

export default router

<template>
  <base-modal
    v-model="createCustomerOpen"
    :header="'Luo uusi asiakkuus'"
    :maxWidth="'700px'"
  >
  <base-validation-form ref="createCustomerForm">
    <customer-form
      v-model="customerData"  
      :editDisabled="false"
    ></customer-form>
    <div class="flex justify-end">
      <button 
        class="btn"
        @click="createCustomer"
      >Luo asiakas</button>
    </div>
  </base-validation-form>
  </base-modal>
</template>
<script>
import CustomerForm from '@/components/CustomerForm'
import createCustomer from '@/graphql/mutations/CreateCustomer.gql'
import { stateMixins } from '@/mixins/state-mixins'
import { commonMixins } from '@/mixins/common-mixins'

export default {
  name: 'CustomerCreator',
  components: {
    CustomerForm
  },
  mixins: [stateMixins, commonMixins],
  props: {
    value: Boolean
  },
  data () {
    return {
      customer: {
        first_name: '',
        last_name: '',
        phone: '',
        email: null
      }
    }
  },
  computed: {
    createCustomerOpen: {
      get () {
        return this.value
      },
      set (createCustomerOpen) {
        this.$emit('input', createCustomerOpen)
      } 
    },
    customerData: {
      get () {
        return this.customer
      },
      set (customerData) {
        this.customer = customerData
      } 
    }
  },
  methods: {
    async createCustomer () {
      const valid = await this.$refs['createCustomerForm'].validate()
      if (
        (this.customer.phone === null || this.customer.phone === '')
        && (this.customer.email === null || this.customer.email === '')
      ) {
        this.setNotification({
          text: 'Sähköposti tai puhelinnumero tulee olla syötetty',
          type: 'warning',
        })
        return
      } 
      if (!valid) {
        this.setNotification({
          text: 'Tarkista kentät',
          type: 'warning',
        })
      } else {
        this.toggleLoader(true)
        try {
          await this.$apollo.mutate({
            mutation: createCustomer,
            variables: this.customer,
          })    
          this.setNotification({
            text: 'Asiakas luotiin',
            type: 'success',
          })
          this.createCustomerOpen = false
        } catch (e) {
          const notificationError = this.getValidationErrors(e)
          this.setNotification({
            text: `Epäonnistui: ${notificationError}`,
            type: 'error',
          })
        } finally {
          this.toggleLoader(false)
        }
      }
    }
  }
}
</script>

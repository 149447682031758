<template>
  <div class="flex justify-center mt-24">
    <div class="login-container">
    <base-validation-form ref="loginForm">
      <ValidationProvider v-slot="{ errors }" name="loginName" rules="required">
        <base-input-field
          v-model="username"
          :label="'Käyttäjänimi'"
          :errors="errors"
        ></base-input-field>
      </ValidationProvider>
      <ValidationProvider v-slot="{ errors }" name="password" rules="required">
        <base-input-field
          v-model="password"
          :label="'Salasana'"
          :inputType="'password'"
          :errors="errors"
        ></base-input-field>
      </ValidationProvider>
      <div class="flex justify-end mt-4">
        <button 
          class="btn"
          @click="login"
        >Kirjaudu</button>
      </div>
    </base-validation-form>
    </div>
  </div>
</template>
<script>
import gql from 'graphql-tag'
import { onLogin } from '@/vue-apollo.js'
import Storage from '@/lib/Storage'
import { stateMixins } from '@/mixins/state-mixins'

export default {
  name: 'LoginView',
  mixins: [stateMixins],
  data () {
    return {
      username: '',
      password: ''
    }
  },
  methods: {
    async login () {
      const valid = await this.$refs['loginForm'].validate()
      if (!valid) {
        this.setNotification({
          text: 'Tarkista kentät',
          type: 'warning',
        })
      } else {
        try {
          const loginData = await this.$apollo.mutate({
            mutation: gql`mutation ($loginData: LoginInput!) {
              login(input: $loginData) {
                access_token
              }
            }`,
            variables: {
              loginData: {
                username: this.username,
                password: this.password
              },
            },
            // Update the cache with the result
            // The query will be updated with the optimistic response
            // and then with the real result of the mutation
          })    
          onLogin(this.$apollo.provider.defaultClient, loginData.data.login.access_token)
          Storage.setItem('isLoggedIn', true)
          this.$router.replace({path: '/'})
        } catch (e) {
          this.setNotification({
            text: 'Väärät tunnistautumistiedot',
            type: 'warning',
          })
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.login-container {
  width: 100%;
  max-width: 750px; 
}
</style>

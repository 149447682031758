<template>
  <div class="mb-8">
    <dates-data v-model="dates"></dates-data>
    <base-divider></base-divider>
    <h3 class="text-lg font-medium leading-6 text-gray-900 mb-4">Omistajan tiedot</h3>
    <base-validation-form ref="updateCustomer">
      <customer-data 
        v-model="customer"
        :hasBeenAdded="customer.id !== null"
        @update="updateCustomer"
      ></customer-data>
    </base-validation-form>
    <base-divider></base-divider>
    <div v-if="customerHasBeenAdded">
      <h3 class="text-lg font-medium leading-6 text-gray-900 mb-4">
        Sammuttimet
      </h3>
      <work-order-form-extinguisher
        v-for="(extinguisher, index) in workOrder.extinguishers"
        :key="extinguisher.id"
        :extinguisher="extinguisher"
        :ref="`extinguisher${index}`"
      ></work-order-form-extinguisher>
      <div class="flex">
        <button class="btn mr-2" @click="qrReaderOpen = true">Lisää sammutin QR-koodilla</button>
        <button class="btn btn-secondary" @click="addNewExtinguisher">Lisää uusi sammutin</button>
      </div>
    </div>
    <div v-else>Lisää omistaja lisätäksesi sammuttimia</div>
    <div class="flex justify-end my-8">
      <button 
        class="btn mr-2"
        @click="saveData"
      >Tallenna sammuttimet</button>
      <button 
        class="btn btn-secondary"
        @click="print"
      >Tulosta</button>
    </div>
    <qr-reader
      v-if="qrReaderOpen"
      @close="qrReaderOpen = false"
      @extinguisherFound="addRemoteExtinguisher"
    ></qr-reader>
  </div>
</template>
<script>
import CustomerData from '@/components/CustomerData'
import DatesData from '@/components/DatesData'
import WorkOrderFormExtinguisher from './WorkOrderFormExtinguisher'
import QrReader from '@/components/QrReader'
import { workOrderQuery, addExtinguisher, setCustomer, editWorkOrder } from '@/graphql/local/queries/work-order'
import { stateMixins } from '@/mixins/state-mixins'
import { v4 as uuidv4  } from 'uuid';
import { asyncForEach } from '@/lib/helpers'
import getExtinguisher from '@/graphql/queries/Extinguisher.gql'
import getCustomer from '@/graphql/queries/Customer.gql'
import createExtinguisher from '@/graphql/mutations/CreateExtinguisher.gql'
import updateExtinguisher from '@/graphql/mutations/UpdateExtinguisher.gql'
import updateCustomer from '@/graphql/mutations/UpdateCustomer.gql'
import { editExtinguisher } from '@/graphql/local/queries/work-order'
import { timeMixins } from '@/mixins/time-mixins'
import { commonMixins } from '@/mixins/common-mixins'
import _ from 'lodash'

export default {
  name: 'WorkOrderForm',
  components: {
    CustomerData,
    DatesData,
    WorkOrderFormExtinguisher,
    QrReader
  },
  mixins: [timeMixins, stateMixins, commonMixins],
  data () {
    return {
      qrReaderOpen: false,
    }
  },
  apollo: {
    workOrder: {
      query: workOrderQuery 
    },
  },
  computed: {
    dates: {
      /**
       * Getter method for the dates
       * @return {Object} 
       */
      get () {
        return {
          received: this.workOrder.received,
          returnDate: this.workOrder.returnDate
        }
      },
    
      /**
       * Setter method for the dates 
       * @param {Object} dates 
       */
      set (dates) {
        this.editBaseData(dates)
      } 
    },
    customer: {
      /**
       * Getter method for the customer
       * @return {Object} 
       */
      get () {
        return this.workOrder ? this.workOrder.customer : null
      },
    
      /**
       * Setter method for the customer 
       * @param {Object} customer 
       */
      set (customer) {
        this.$apollo.mutate({
          mutation: setCustomer,
          variables: { 
            customer: {
              ...this.workOrder.customer,
              ...customer
            }
          }
        })
      } 
    },
    customerHasBeenAdded () {
     return Object.prototype.hasOwnProperty.call(this.workOrder, 'customer')  
              && this.workOrder.customer.id !== null
    }
  },
  methods: {
    print () {
      this.$router.replace({path: 'tyomaarays-tulostus'})
    },
    addNewExtinguisher () {
      this.addExtinguisher({
        __typename: 'Extinguisher',
        id: uuidv4(),
        serial_number: '',
        received: this.toDate(Date.now()),
        weight: 0,
        model: '',
        qr_code: null,
        inspection_interval: '1v',
        maintenance_types: null,
        isNew: true,
        price: 0,
        model_year: null,
        customer_id: this.workOrder.customer.id
      })
    },
    addRemoteExtinguisher (extinguisher) {
      this.addExtinguisher({
        ...extinguisher,
      })
    },
    addExtinguisher (extinguisher) {
      const hasExtinguisher = this.workOrder.extinguishers.some(item => item.id === extinguisher.id)
      if (!hasExtinguisher) {
        this.$apollo.mutate({
          mutation: addExtinguisher,
          variables: { 
            extinguisher: {
              ...extinguisher,
            }
          }
        })
        this.qrReaderOpen = false
      } else {
        this.qrReaderOpen = false
        this.setNotification({
          text: 'Sammutin on jo lisätty',
          type: 'warning',
        })
      }
    },
    editBaseData (payload) {
      this.$apollo.mutate({
        mutation: editWorkOrder,
        variables: { 
          workOrder: {
            ...payload
          }
        }
      })
    },
    async saveData () {
      if (this.customerHasBeenAdded) {
        let valid = true
        await asyncForEach(this.workOrder.extinguishers, async (extinguisher, index) => {
          const validExtinguisher = await this.$refs[`extinguisher${index}`][0].validate()
          if (!validExtinguisher) {
            valid = false
          }
        })
        console.log(valid)
        if (!valid) {
          this.setNotification({
            text: 'Tarkista sammuttimien tiedot',
            type: 'warning',
          })
        } else {
          try {
            await asyncForEach(this.workOrder.extinguishers, async extinguisher => {
              if (extinguisher.isNew) {
                await this.$apollo.mutate({
                  mutation: createExtinguisher,
                  variables: {
                    ...extinguisher,
                    id: extinguisher.id,
                    maintenance_types: extinguisher.maintenance_types,
                    maintained: false,
                    received: this.workOrder.received
                  },
                })    
                this.$apollo.mutate({
                  mutation: editExtinguisher,
                  variables: { 
                    extinguisher: {
                      __typename: 'Extinguisher',
                      ...extinguisher,
                      isNew: false
                    }
                  }
                })
              } else {
                await this.$apollo.mutate({
                  mutation: updateExtinguisher,
                  variables: {
                    id: extinguisher.id,
                    model: extinguisher.model,
                    serial_number: extinguisher.serial_number,
                    weight: extinguisher.weight,
                    qr_code: extinguisher.qr_code,
                    model_year: extinguisher.model_year,
                    inspection_interval: extinguisher.inspection_interval,
                    maintenance_types: extinguisher.maintenance_types,
                    maintained: false,
                    received: this.workOrder.received
                  },
                    update: (store, { data: { updateExtinguisher } }) => {
                      // Read the data from our cache for this query.
                      const data = store.readQuery({ 
                        query: getExtinguisher,
                        variables: extinguisher.id 
                      })
                      data.extinguisher = updateExtinguisher
                      // Write our data back to the cache.
                      store.writeQuery({ query: getExtinguisher, data })
                    },
                }) 
              }
            })
          this.setNotification({
            text: 'Tallennettiin mahdolliset uudet sammuttimet ja asetettiin sammuttimet huollettaviksi',
            type: 'success',
          })
          } catch (e) {
            const notificationError = this.getValidationErrors(e)
            this.setNotification({
              text: `Epäonnistui: ${notificationError}`,
              type: 'error',
            })
          } 
        }
      }
    },
    async updateCustomer () {
      const valid = await this.$refs['updateCustomer'].validate()
      if (!valid) {
        this.setNotification({
          text: 'Tarkista kentät',
          type: 'warning',
        })
      } else {
        this.toggleLoader(true)
        try {
          let customerData = _.cloneDeep(this.workOrder.customer)
          if((customerData.email == null || customerData.email =="" ) &&  (customerData.phone == null || customerData.phone =="" )){
            this.setNotification({
              text: 'Sähköpostin tai puhelinnumeron tulee olla syötetty',
              type: 'warning',
            })
            return;
          }
          if (Object.prototype.hasOwnProperty.call(customerData, '__typename')) {
            delete customerData['__typename']
          }
          await this.$apollo.mutate({
            mutation: updateCustomer,
            variables: {
              ...customerData,
            },
              update: (store, { data: { updateCustomer } }) => {
                // Read the data from our cache for this query.
                const data = store.readQuery({ 
                  query: getCustomer,
                  variables: {id: customerData.id}
                })
                data.customer = updateCustomer
                // Write our data back to the cache.
                store.writeQuery({ query: getCustomer, data })
              },
          }) 
          this.setNotification({
            text: 'Omistajan tiedot tallennettiin',
            type: 'success',
          })
          /*this.$apollo.queries.extinguisher.refetch()*/
        } catch (e) {
            const notificationError = this.getValidationErrors(e)
            this.setNotification({
              text: `Epäonnistui: ${notificationError}`,
              type: 'error',
            })
          }  finally {
          this.toggleLoader(false)
        }
      }
    },
  }
}
</script>

<template>
  <div>
    <work-order-form></work-order-form>
  </div>
</template>
<script>
import WorkOrderForm from '@/components/work-order-form/WorkOrderForm'
export default {
  name: 'WorkOrderView',
  components: {
    WorkOrderForm
  }

}
</script>

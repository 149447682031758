<template>
  <div>
    <base-tabs
      :tabs="tabRoutes"
      :useRoutes="true"
      @changeTab="changeTab"
    ></base-tabs>
    <router-view></router-view>
  </div>
</template>
<script>
import routes from '@/router/routes'

export default {
  name: 'ExtinguisherEditView',
  computed: {
    tabRoutes () {
      const parentRoute = routes.find(route => {
        return route.name === 'extinguisher-edit'
      })
      return parentRoute
        ? parentRoute.children
        : []
    },
  },
  methods: {
    changeTab (tab) {
      const id = this.$route.params.id
      this.$router.replace( { path: `/sammutin/muokkaa/${id}/${tab.path}` } )
    }
  }
}
</script>

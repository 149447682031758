export default {
	data () {
		return {
			animationDone: false
		}
	},
  computed: {
    fadeIn () {
      return {
        'animate__animated': this.animationDone,
        'animate__fadeIn': this.animationDone,
      }
    }, 
    fadeInFaster () {
      return {
        'animate__animated': this.animationDone,
        'animate__fadeIn': this.animationDone,
        'animate__faster': this.animationDone,
      }
    }, 
    fadeInUp () {
      return {
        'animate__animated': this.animationDone,
        'animate__fadeInUp': this.animationDone,
      }
    }, 
    fadeInUpFaster () {
      return {
        'animate__animated': this.animationDone,
        'animate__fadeInUp': this.animationDone,
        'animate__faster': this.animationDone,
      }
    } 
  },
}

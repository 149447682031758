import { toggleLoader } from '@/graphql/local/queries/loader'
import { setNotification } from '@/graphql/local/queries/notification'

export const stateMixins = {
	methods: {
		toggleLoader (show) {
			this.$apollo.mutate({
				mutation: toggleLoader,
				variables: { 
					showLoader: show 
				}
			})
		},
		setNotification (notification) {
			this.$apollo.mutate({
				mutation: setNotification,
				variables: { 
					text: notification.text,
					type: notification.type,
					show: true 
				}
			})
			setTimeout(this.hideNotification, 3000);
		},
		hideNotification () {
			this.$apollo.mutate({
				mutation: setNotification,
				variables: { 
					text: '',
					type: '',
					show: false 
				}
			})
		}
	},
}

<template>
  <div class="hidden sm:block">
    <div class="py-3">
      <div class="border-t border-gray-200"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BaseDivider'
}
</script>

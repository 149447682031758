import BaseInputField from '@/components/base/BaseInputField'
import BaseTabs from '@/components/base/BaseTabs'
import BaseSpinner from '@/components/base/BaseSpinner'
import BaseDivider from '@/components/base/BaseDivider'
import BaseDatePicker from '@/components/base/BaseDatePicker'
import BaseBackLink from '@/components/base/BaseBackLink'
import BaseChip from '@/components/base/BaseChip'
import BaseSearchField from '@/components/base/BaseSearchField'
import BaseModal from '@/components/base/BaseModal'
import BaseListCard from '@/components/base/BaseListCard'
import BaseSelect from '@/components/base/BaseSelect'
import BaseValidationForm from '@/components/base/BaseValidationForm'

export default [
	BaseInputField,
	BaseTabs,
	BaseSpinner,
	BaseDivider,
	BaseDatePicker,
	BaseBackLink,
	BaseChip,
	BaseSearchField,
	BaseModal,
	BaseListCard,
	BaseSelect,
	BaseValidationForm,
]


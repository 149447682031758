<template>
  <div class="relative">
    <input 
      v-model="search"
      type="search"
      class="w-full bg-gray-200 pl-10 pr-4 py-2 rounded-lg shadow focus:outline-none focus:shadow-outline text-gray-600 font-medium"
      :placeholder="placeholder"
    >
    <div class="absolute top-0 left-0 inline-flex items-center p-2 cursor-pointer">
      <svg class="w-6 h-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
      </svg>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BaseSearchField',
  props: {
    value: String,
    placeholder: {
      type: String,
      default: 'Hae...'
    },
  },
  computed: {
    search: {
      /**
       * Getter method for the search
       * @return {String} 
       */
      get () {
        return this.value
      },
    
      /**
       * Setter method for the search 
       * @param {String} search 
       */
      set (search) {
        this.$emit('input', search)
      } 
    }
  }
}
</script>


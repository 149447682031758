<template>
  <div>
   <label class="block text-sm font-medium leading-5 text-gray-700 mb-1">
     {{label}}
  </label>
  <input 
    v-model="fieldValue"
    class="appearance-none block w-full bg-gray-200 border rounded py-3 px-4 mb-3" 
    :class="[{'text-gray-600': disabled}, {'text-gray-700': !disabled}]"
    :type="inputType" 
    placeholder=""
    :disabled="disabled"
  >
    <p 
      v-if="errors.length > 0"
      class="text-red-400 text-xs italic">{{errors[0]}}
    </p>
  </div>
</template>
<script>
export default {
  name: 'BaseInputField',
  props: {
    value: {
      required: true,
      default: ''
    },
    label: {
      type: String,
    },
    inputType: {
      type: String,
      default: 'text'
    },
    errors: {
      type: [String, Array],
      default: () => [] 
    },
    identifier: {
      type: [String, Number]
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      typeErrors: []
    }
  },
  computed: {
    fieldValue: {
      /**
       * Getter method for the fieldValue
       * @return {String} 
       */
      get () {
        return this.value
      },
    
      /**
       * Setter method for the fieldValue 
       * @param {String} fieldValue 
       */
      set (fieldValue) {
        let data = fieldValue
        if (this.identifier) {
          data = {
            identifier: this.identifier,
            value: fieldValue
          }
        }
        if (this.inputType === 'number') {
          if (
            /^[0-9,.]*$/.test(fieldValue)
            && !isNaN(parseFloat(fieldValue)
            )) {
            this.$emit('input', data)
          }  
        } else {
          this.$emit('input', data)
        }
      } 
    }
  }
}
</script>


<template>
  <div>
    <ApolloQuery
      :query="require('@/graphql/queries/Maintenances.gql')"
      :variables="{ extinguisher_id: $route.params.id}"
    >
      <template v-slot="{ result: { loading, error, data} }">
        <div v-if="loading" class="loading apollo">Ladataan...</div>
        <div v-else-if="error" class="error apollo">An error occurred</div>
        <div v-else-if="data" class="error apollo">
          <base-list-card
            v-for="maintenance in data.maintenances"
            :key="maintenance.id"
            :cardData="getCardData(maintenance)"
            @edit="goToMaintenanceEdit"
          ></base-list-card>
        </div>
        <div v-else class="no-result apollo">No result :(</div>
      </template>
    </ApolloQuery>
  </div>
</template>
<script>
import BaseListCard from '@/components/base/BaseListCard'
import {timeMixins} from '@/mixins/time-mixins'

export default {
  name: 'ExtinguisherEditMaintenancesView',
  components: {
    BaseListCard
  },
  mixins: [timeMixins],
  methods: {
    getCardData (maintenance) {
      return {
        id: maintenance.id,
        title: {
          text: this.maintenanceTypes(maintenance),
          color: 'text-black'
        },
        items: [
          {
            subtitle: 'Huollon tekijä',
            value: maintenance.maintainer ? `${maintenance.maintainer.first_name} ${maintenance.maintainer.last_name}`: 'Ei lisätty'
          },
          {
            subtitle: 'Toimenpiteet tehty',
            value: this.formatDay(maintenance.date_completed) 
          },
        ]
      }
    },
    goToMaintenanceCreate () {
      this.$router.replace({path: `/huolto/${this.$route.params.id}/luo`})
    },
    goToMaintenanceEdit (id) {
      this.$router.replace({path: `/huolto/${this.$route.params.id}/muokkaa/${id}`})
    },
    maintenanceTypes (maintenance) {
      if (maintenance.types === null || maintenance.types === '') {
        return ''
      } else {
        let typesString = ''
        const types = JSON.parse(maintenance.types)
        if (types === null) {
          return ''
        }
        types.forEach((type, index) => {
          const typeName = ((maintenanceType) => {
            switch(maintenanceType) {
              case 'repair':
                return 'Korjaus'
              case 'fill':
                return 'Täyttö'
              case 'inspection':
                return 'Tarkastus'
              default:
                return 'Huolto'
            } 
          })(type)  
          const comma = index === 0
            ? ''
            : ', '
          typesString = ` ${typesString}${comma}${typeName}`
        })
        return typesString
      }
    },
  }
}
</script>

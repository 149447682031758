<template>
  <base-validation-form ref="workOrderExtinguisher">
  <div class="mb-8 w-full rounded overflow-hidden shadow-lg px-4 py-3">
    <div class="flex justify-between">
      <h4 class="text font-medium leading-6 text-gray-900 mb-4">
        Sammutin: {{extinguisher.serial_number}}
      </h4>
      <span 
        class="cursor-pointer"
        @click="removeExtinguisher"
      >
        <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
        </svg>
      </span>
    </div>
    <extinguisher-data
      v-model="extinguisherData"
    ></extinguisher-data>
    <h4 class="text-sm font-medium leading-6 text-gray-900 mb-4 mt-3">
      Tehtävät toimenpiteet
    </h4>
    <maintenance-type
      :chosen="types" 
      @toggleChip="toggleChip"
    ></maintenance-type>
    <div class="w-full sm:w-1/3 sm:pr-1 mt-4">
      <base-input-field
        v-model="price"
        :inputType="'number'"
        :label="'Hinta toimenpiteille (€ sis. ALV)'"
      ></base-input-field>
    </div>
  </div>
  </base-validation-form>
</template>
<script>
import ExtinguisherData from '@/components/ExtinguisherData'
import MaintenanceType from '@/components/MaintenanceType'
import { editExtinguisher, removeExtinguisher } from '@/graphql/local/queries/work-order'

export default {
  name: 'WorkOrderExtinguisher',
  components: {
    ExtinguisherData,
    MaintenanceType
  },
  props: {
    extinguisher: Object
  },
  computed: {
    price: {
      get () {
        return this.extinguisherData.price
      },
      set (price) {
        this.extinguisherData = {
          ...this.extinguisherData,
          price: parseFloat(price)
        }
      } 
    },
    extinguisherData: {
      get () {
        return this.extinguisher
      },
      set (extinguisherData) {
        this.$apollo.mutate({
          mutation: editExtinguisher,
          variables: { 
            extinguisher: {
              __typename: 'Extinguisher',
              ...extinguisherData,
            }
          }
        })
      } 
    },
    types () {
      return Object.prototype.hasOwnProperty.call(this.extinguisherData, 'maintenance_types')
        ? this.extinguisherData.maintenance_types
        : null
    }
  },
  methods: {
    async validate () {
      const valid = await this.$refs['workOrderExtinguisher'].validate()
      return valid
    },
    removeExtinguisher () {
      this.$apollo.mutate({
        mutation: removeExtinguisher,
        variables: { 
          id: this.extinguisher.id
        }
      })
    },
    toggleChip(types) {
      this.extinguisherData = {
        ...this.extinguisherData,
        maintenance_types: types
      }
    }
  }
}
</script>

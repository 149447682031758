import gql from 'graphql-tag';

const todoItemsQuery = gql`
  {
    todoItems @client {
      id
      text
      done
    }
  }
`;

const loaderQuery = gql`
	query showLoader {
		showLoader @client
	}
`;

const toggleLoader = gql`
  mutation($showLoader: Boolean) {
		toggleLoader(showLoader: $showLoader) @client
  }
`;

const loaderMutations = {
	checkItem (_, {id}, { cache }) {
      const data = cache.readQuery({ query: todoItemsQuery });
      const currentItem = data.todoItems.find(item => item.id === id);
      currentItem.done = !currentItem.done;
      cache.writeQuery({ query: todoItemsQuery, data });
      return currentItem.done;
	},
	toggleLoader (_, {showLoader}, { cache }) {
		const data = cache.readQuery({ query: loaderQuery });
		data.showLoader = showLoader
		cache.writeQuery({ query: loaderQuery, data });
		return data.showLoader
	}
}

export {loaderMutations, toggleLoader, loaderQuery}

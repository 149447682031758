<template>
  <div class="flex">
    <base-chip
      v-for="maintenanceType in types"
      :key="maintenanceType.value"
      :isChosen="isChosen(maintenanceType.value)"
      @click="toggleChip(maintenanceType.value)"
    >{{maintenanceType.text}}</base-chip>
  </div>
</template>
<script>
export default {
  name: 'MaintenanceType',
  props: {
    chosen: String 
  },
  data () {
    return {
      types: [
        {
          text: 'Täyttö',
          value: 'fill'
        },
        {
          text: 'Huolto',
          value: 'maintenance'
        },
        {
          text: 'Korjaus',
          value: 'repair'
        },
        {
          text: 'Tarkastus',
          value: 'inspection'
        }
      ]
    }
  },
  computed: {
    chosenTypes () {
      return this.chosen === null || this.chosen === '' 
      ? []
      : JSON.parse(this.chosen)
    }
  },
  methods: {
    isChosen (type) {
      return this.chosenTypes.includes(type)
    },
    toggleChip (type) {
      const chosenTypes = [...this.chosenTypes] 
      const index = chosenTypes.indexOf(type)
      if (index !== -1) {
        chosenTypes.splice(index, 1)
      } else {
        chosenTypes.push(type)
      }
      console.log(JSON.stringify(chosenTypes))
      this.$emit('toggleChip', JSON.stringify(chosenTypes))
    }
  }
}
</script>

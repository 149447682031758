<template>
  <div class="flex">
    <base-chip
      v-for="inspection in types"
      :key="inspection.value"
      :isChosen="chosen === inspection.value"
      @click="toggleChip(inspection.value)"
    >{{inspection.text}}</base-chip>
  </div>
</template>
<script>
export default {
  name: 'InspectionInterval',
  props: {
    chosen: String 
  },
  data () {
    return {
      types: [
        {
          text: '1v',
          value: '1v'
        },
        {
          text: '2v',
          value: '2v'
        },
      ]
    }
  },
  methods: {
    toggleChip (type) {
      this.$emit('toggleChip', type)
    }
  }
}
</script>

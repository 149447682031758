<template>
  <div>
    <div class="flex flex-wrap">
      <div class="w-full sm:w-1/2 sm:pr-1">
        <ValidationProvider v-slot="{ errors }" name="extinguisher_model" rules="required">
          <base-input-field
            v-model="model"
            :label="'Sammuttimen malli'"
            :errors="errors"
          ></base-input-field>
        </ValidationProvider>
      </div>
      <div class="w-full sm:w-1/2 sm:pl-1">
        <ValidationProvider v-slot="{ errors }" name="extinguisher_serial_number" rules="required">
        <base-input-field
          v-model="serial_number"
          :label="'Sammuttimen sarjanumero'"
          :errors="errors"
        ></base-input-field>
        </ValidationProvider>
      </div>
    </div>
    <div class="flex flex-wrap">
      <div class="w-full sm:w-1/2 sm:pr-1">
        <ValidationProvider v-slot="{ errors }" name="extinguisher_weight" rules="required">
        <base-input-field
          v-model="weight"
          :label="'Sammuttimen paino'"
          :errors="errors"
          :inputType="'number'"
        ></base-input-field>
        </ValidationProvider>
      </div>
      <div class="w-full sm:w-1/2 sm:pl-1">
        <ValidationProvider v-slot="{ errors }" name="extinguisher_year" rules="required">
        <base-input-field
          v-model="model_year"
          :label="'Sammuttimen vuosimalli'"
          :errors="errors"
          :inputType="'number'"
        ></base-input-field>
        </ValidationProvider>
      </div>
    </div>
    <div class="w-full">
     <label class="block text-sm font-medium leading-5 text-gray-700 mb-1 mt-2">
       Tarkastusväli
    </label>
    </div>
    <inspection-interval
      :chosen="value.inspection_interval" 
      @toggleChip="changeInspectionInterval"
    ></inspection-interval>
    <div v-if="qr_code">
      <div 
        class="mt-4 mb-4 text-green-600"
      >
        QR-koodi lisätty (Koodi: {{qr_code}})
      </div>
      <button 
        class="btn btn-tertiary"
        @click="qrReaderOpen = true"
      >Vaihda QR-koodi</button>
    </div>
    <div v-else>
      <div 
        class="mt-4 mb-4 text-red-600"
      >QR-koodi puuttuu</div>
      <button 
        class="btn btn-tertiary"
        @click="qrReaderOpen = true"
      >Lisää QR-koodi</button>
    </div>
    <qr-reader
      v-if="qrReaderOpen"
      :operationType="'scan'"
      @close="qrReaderOpen = false"
      @getCode="addCode"
    ></qr-reader>
  </div>
</template>
<script>
import QrReader from '@/components/QrReader'
import InspectionInterval from '@/components/InspectionInterval'

export default {
  name: 'ExtinguisherData',
  components: {
    QrReader,
    InspectionInterval
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      qrReaderOpen: false
    }
  },
  computed: {
    weight: {
      get () {
        return this.value.weight
      },
      set (weight) {
        const data = parseFloat(weight)
        this.edit({
          weight: data
        })
      } 
    },
    model: {
      get () {
        return this.value.model
      },
      set (model) {
        this.edit({
          model: model 
        })
      } 
    },
    model_year: {
      get () {
        return this.value.model_year
      },
      set (model_year) {
        const data = parseInt(model_year)
        this.edit({
          model_year: data 
        })
      } 
    },
    qr_code: {
      get () {
        return this.value.qr_code
      },
      set (qr_code) {
        this.edit({
          qr_code: qr_code 
        })
      } 
    },
    serial_number: {
      get () {
        return this.value.serial_number
      },
      set (serial_number) {
        this.edit({
          serial_number: serial_number 
        })
      } 
    },
  },
  methods: {
    edit (payload) {
      this.$emit('input', {
        ...this.value,
        ...payload
      })
    },
    changeInspectionInterval (inspection) {
      this.edit({
        inspection_interval: inspection
      })
    },
    addCode (code) {
      this.qr_code = code
      this.qrReaderOpen = false
    }
  }
}
</script>

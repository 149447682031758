<template>
  <div 
    class="flex mb-4 cursor-pointer"
    @click="$emit('click')"
  >
    <svg class="w-5 h-5 " xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#4B5563">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
    </svg>
    <div class="ml-2 text-gray-600">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BaseBackLink',
}
</script>

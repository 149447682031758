<template>
  <div class="home">
    <div class="mt-10 md:mt-20">
      <div class="flex justify-center items-center">
        <button 
          class="btn mr-1"
          @click="qrReaderOpen = true"
        >Lue QR-koodi</button>
        <button 
          class="btn mx-1"
          @click="goToCreateWorkOrder"
        >Luo työmääräys</button>
        <button 
          class="btn btn-secondary ml-1"
          @click="goToCreateExtinguisher"
        >Lisää sammutin</button>
      </div>
      <div class="flex justify-center items-center mt-6">
      </div>
    </div>
    <extinguisher-list></extinguisher-list>
    <qr-reader
      v-if="qrReaderOpen"
      @close="qrReaderOpen = false"
      @extinguisherFound="extinguisherFound"
    ></qr-reader>
    <base-modal
      v-model="extinguisherFoundOpen"
      :header="'Sammutin löytyi'"
      :maxWidth="'500px'"
    >
    <div 
      v-if="foundExtinguisher"
      class="flex flex-col items-center"
    >
      <div>Sarjanumero: {{foundExtinguisher.serial_number}}</div>
      <div v-if="foundExtinguisher.owner">
        Omistaja: {{foundExtinguisher.owner.first_name}} {{foundExtinguisher.owner.last_name}} ({{foundExtinguisher.owner.email}})
      </div>
      <div v-else>Omistajaa ei asetettu</div>
      <div class="mt-3">Tila:</div>
      <div :class="[
        'text-xl',
        {'text-green-600': foundExtinguisher.maintained},
        {'text-orange-600': !foundExtinguisher.maintained}
        ]">{{extinguisherMaintainedText}}</div>
      <div class="inline-flex justify-center mt-8">
        <button 
          class="btn mr-2"
          @click="createWorkOrderWithExtinguisher"
        >Luo työmääräys</button>
        <button 
          class="btn ml-2"
          @click="goToExtinguisherEdit"
        >Muokkaa sammutinta</button>
      </div>
    </div>
    </base-modal>
  </div>
</template>

<script>
import ExtinguisherList from '@/components/ExtinguisherList'
import QrReader from '@/components/QrReader'
import { editWorkOrder } from '@/graphql/local/queries/work-order'
import BaseModal from '@/components/base/BaseModal'
import moment from 'moment'

export default {
  name: 'Home',
  components: {
    ExtinguisherList,
    QrReader,
    BaseModal
  },
  created () {
  },
  data () {
    return {
      qrReaderOpen: false,
      extinguisherFoundOpen: false,
      foundExtinguisher: null
    }
  },
  computed: {
    extinguisherMaintainedText () {
      if (this.foundExtinguisher) {
        return this.foundExtinguisher.maintained
          ? 'Huollettu'
          : 'Huollossa'
      } else {
        return ''
      }
    } 
  },
  methods: {
    goToCreateExtinguisher () {
      this.$router.replace({path: '/sammutin/luo'})
    },
    goToCreateWorkOrder () {
      this.$apollo.mutate({
        mutation: editWorkOrder,
        variables: { 
          workOrder: {
            __typename: 'WorkOrder',
            received: moment().format('YYYY-MM-DD'), 
            returnDate: moment().add(1, 'days').format('YYYY-MM-DD'), 
            customer: {
              __typename: 'Customer',
              id: null,
              first_name: '',
              last_name: '',
              phone: '',
              email: '',
              street_address: null,
              zip_code: null,
              city: null
            },
            extinguishers: []
          }
        }
      })
      this.$router.replace({path: '/tyomaarays'})
    },
    extinguisherFound (extinguisher) {
      this.foundExtinguisher = extinguisher
      this.extinguisherFoundOpen = true
      this.qrReaderOpen = false
    },
    goToExtinguisherEdit () {
      this.$router.replace({path: `/sammutin/muokkaa/${this.foundExtinguisher.id}`})
    },

    /**
     * Create workorder and set extinguisher and customer 
     */
    createWorkOrderWithExtinguisher () {
      this.$apollo.mutate({
        mutation: editWorkOrder,
        variables: { 
          workOrder: {
            __typename: 'WorkOrder',
            received: moment().format('YYYY-MM-DD'), 
            returnDate: moment().add(1, 'days').format('YYYY-MM-DD'), 
            customer: {
              ...this.foundExtinguisher.owner
            },
            extinguishers: [{
              ...this.foundExtinguisher,
              maintenance_types: "[]"
            }]
          }
        }
      })
      this.$router.replace({path: '/tyomaarays'})
    },
  }
}
</script>

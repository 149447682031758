<template>
  <div >
    <div 
      class="fixed inset-0 opacity-75 bg-black"
    ></div>
    <div class="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
      <div class="camera-container">
        <div
          v-show="$apollo.queries.extinguisher.loading || !cameraLoaded"
          style="width:200px;height:300px"
          class="relative"
        >
          <div 
            class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center items-center"
            style="width:250px;height:175px"
          >
            <base-spinner></base-spinner>
          </div>
        </div>
          <div 
            v-if="noDataInCode"
            class="text-white w-full text-center mt-4 text-xl"
          >
            Koodi ei sisällä hakuun kelpaavaa tietoa
          </div>
          <div 
            v-else-if="!$apollo.queries.extinguisher.loading && notFound"
            class="text-white w-full text-center mt-4 text-xl"
          >
            Sammutinta ei löytynyt koodilla
          </div>
          <div 
            v-else-if="$apollo.queries.extinguisher.loading"
            class="text-white w-full text-center mt-4 text-xl"
          >Haetaan sammutinta</div>
          <div 
            v-else-if="!cameraLoaded"
            class="text-white w-full text-center mt-4 text-xl"
          >Aktivoidaan kameraa</div>
          </div>
        <div 
          v-show="!$apollo.queries.extinguisher.loading  && cameraLoaded && !notFound && !noDataInCode"
          class="mx-auto"
        >
          <div class="text-white w-full text-center mb-3">
            Kohdista kamera qr-koodin kohdalle
          </div>
            <canvas  
              :class="fadeIn"
              id="qr-canvas"
            ></canvas>
          </div>
          <div class="flex justify-center mt-4">
            <button 
              v-if="notFound || noDataInCode"
              class="btn mr-2" 
              @click="reset"
            >Skannaa uudestaan</button>
            <button 
              class="btn" 
              @click="close"
            >Sulje</button>
          </div>
    </div>
  </div>
</template>

<script>
import jsQR from 'jsqr';
import animationMixins from '@/mixins/animation-mixins'
import getExtinguisher from '@/graphql/queries/ExtinguisherQR.gql'

export default {
  mixins: [animationMixins],
  props: {
    operationType: {
      type: String,
      default: 'find'
    }
  },
  data () {
    return {
      cameraLoaded: false,
      dialog: null,
      video: null,
      canvas: null,
      searching: false,
      variables: null,
      extinguisher: null,
      noDataInCode: false,
      notFound: false,
    }
  },
  mounted () {
    this.animationDone = true
    this.open()
  },
  apollo: {
    extinguisher: {
      query: getExtinguisher,
      variables () {
        return this.variables
      },
      skip () {
        return !this.variables
      },
      error (error) {
        console.log(error.message)
      },
      result (result) {
        console.log('result', result)
        const extinguisher = result.data.extinguisher_qr
        if (extinguisher === null) {
          this.variables = null
          this.notFound = true
        } else {
          this.$emit('extinguisherFound', extinguisher)
        }
      }
    } 
  },
  methods: {
    open () {
      if (!('mediaDevices' in navigator) || !('getUserMedia' in navigator.mediaDevices)) {
        alert('mediadevices not supported')
        console.log('mediaDevices not supported')
      }
      // Create video element for capturing image from user's camera
      this.video = document.createElement('video');
      this.video.width = "200"
      const options = {
        video: {
            facingMode: 'environment'
        }
      };
      navigator.mediaDevices.getUserMedia(options)
        .then((stream) => {
           // Hide loading text
          this.cameraLoaded = true

          // Attach stream to video element
          this.video.srcObject = stream;
          this.video.setAttribute('playsinline', true); // required to tell iOS safari we don't want fullscreen
          this.video.play();

          // Update image
          requestAnimationFrame(() => this.tick());
        })
        .catch((e) => {
          console.log(e.message)
        });
    },

    // Update video image
    tick() {
      const canvas = document.getElementById('qr-canvas');
      const video = this.video;

      // Wait until video is ready
      if (video && video.readyState === video.HAVE_ENOUGH_DATA) {
        // Get drawing context
        const ctx = canvas.getContext('2d');

        // Resize canvas to match the video
        /*canvas.height = video.videoHeight;*/
        /*canvas.width = video.videoWidth;*/
        canvas.height = 300
        canvas.width = 300

        // Copy frame from video stream to canvas
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

        // Read image data from canvas
        const img = ctx.getImageData(0, 0, canvas.width, canvas.height);

        // Try to extract QR code from image
        const code = jsQR(img.data, img.width, img.height, {
            inversionAttempts: 'dontInvert'
        });
        if (code) {
              // Highlight QR code in image
              const p = code.location;
              ctx.lineWidth = 2;
              ctx.strokeStyle = '#e74c3c';
              ctx.beginPath();
              ctx.moveTo(p.topLeftCorner.x, p.topLeftCorner.y);
              ctx.lineTo(p.topRightCorner.x, p.topRightCorner.y);
              ctx.lineTo(p.bottomRightCorner.x, p.bottomRightCorner.y);
              ctx.lineTo(p.bottomLeftCorner.x, p.bottomLeftCorner.y);
              ctx.closePath();
              ctx.stroke();

              this.setValue(code.data);
              return;
          }
      }
      // Refresh screen
      if (video) {
          requestAnimationFrame(() => this.tick());
      }
    },
    // Set QR code
    setValue: function (value) {
      if (typeof value !== 'string' || value === '') {
        this.noDataInCode = true
      } else {
        if (this.operationType === 'find') {
          setTimeout(() => {
            this.variables = {qr_code: value}
          }, 700)
        } else {
          setTimeout(() => {
            this.$emit('getCode', value)
          }, 700)
        }
      }
    },
    reset () {
      this.noDataInCode = false
      this.notFound = false
      const canvas = document.getElementById('qr-canvas');
      const ctx = canvas.getContext('2d');
      ctx.restore()
      this.tick()
    },
    // Close popup window
    close() {
      if (this.video) {
          this.video.pause();
      }
      this.video = null;
      this.$emit('close')
    }
  }
}
</script>

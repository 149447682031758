<template>
  <base-modal 
    v-model="isOpen"
    :header="'Hae asiakasta'"
    class="customer-search"
    :maxWidth="'900px'"
  >
  <base-search-field
    v-model="searchWord"
    :placeholder="'Hae asiakasta'"
    class="mb-5"
  ></base-search-field>
  <div>
    <base-list-card
      v-for="cardData in customerListData"
      :key="cardData.id"
      :cardData="cardData"
      :breakpoint="'xl'"
      @choose="chooseCustomer(cardData.id)"
    ></base-list-card>
  </div>
  <div 
    class="flex justify-center mt-4">
    <button 
      v-if="hasMore"
      class="btn"
      @click="getMore"
    >Lataa lisää</button>
    <span v-else>Ei enempää käyttäjiä rajauksilla</span>
  </div>
  </base-modal>
</template>
<script>
import gql from 'graphql-tag'

export default {
  name: 'CustomerSearch',
  props: {
    value: Boolean
  },
  data () {
    return {
      customers: [],
      search: '',
      hasMore: false, 
      variables: {
        conditions: null,
        after: null
      } 
    }
  }, 
  apollo: {
    customerSearch: {
      query: gql`query (
        $conditions: QueryCustomerSearchWhereWhereConditions
        $after: String
      ) {
        customerSearch (
          first: 10
          where: $conditions 
          after: $after
        ) {
          edges {
            node {
              id
              first_name
              last_name
              email
              phone
              street_address
              zip_code
              city
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
      }`,
      variables () {
        return this.variables
      },
      error (error) {
        console.log(error.message)
      },
      result ({ data: {customerSearch: {pageInfo}} }) {
        this.endCursor = pageInfo.endCursor
        this.hasMore = pageInfo.hasNextPage
      }
    } 
  }, 
  mounted () {
    this.$apollo.queries.customerSearch.refetch()
  },
  computed: {
    isOpen: {
      get () {
        return this.value
      },
      set (isOpen) {
        this.$emit('input', isOpen)
      } 
    },
    searchWord: {
      get () {
        return this.search
      },
      set (searchWord) {
        this.search = searchWord
        if (searchWord !== null && searchWord.length > 0) {
          let conditions = {
            'OR': [
              { column: 'FIRST_NAME', operator: 'LIKE', value: `${searchWord}%`},
              { column: 'LAST_NAME', operator: 'LIKE', value: `${searchWord}%` },
              { column: 'EMAIL', operator: 'LIKE', value: `${searchWord}%` }
            ]
          }
          this.variables = {
            conditions: conditions
          }
        } else {
          this.variables = null
        }
      } 
    },
    customerListData () {
      if (this.customerSearch && this.customerSearch.edges) {
        const customers = this.customerSearch.edges.map(edge => edge.node)
        return customers.map(item => ({
          id: item.id,
          hasChooseButton: true,
          title: {
            text: `${item.first_name} ${item.last_name}`,
            color: 'gray-600'
          },
          items: [
            {
              subtitle: 'Sähköposti',
              value: item.email
            },
            {
              subtitle: 'Puhelinnumero',
              value: item.phone
            },
          ]
        }))
      } else {
        return []
      }
    },
  },
  methods: {
    getMore () {
      // Fetch more data and transform the original result
      this.$apollo.queries.customerSearch.fetchMore({
        // New variables
        variables: {
          ...this.variables,
          after: this.endCursor 
        },
        // Transform the previous result with new data
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const newEdges = fetchMoreResult.customerSearch.edges

          return {
            customerSearch: {
              __typename: previousResult.customerSearch.__typename,
              // Merging the tag list
              edges: [...previousResult.customerSearch.edges, ...newEdges],
              pageInfo: fetchMoreResult.customerSearch.pageInfo
            },
          }
        },
      })
    },
    chooseCustomer (customer_id) {
      const customerEdge = this.customerSearch.edges.find(edge => edge.node.id === customer_id)
      const customer = {...customerEdge.node}
      this.$emit('chooseCustomer' ,{
        id: customer.id,
        first_name: customer.first_name,
        last_name: customer.last_name,
        phone: customer.phone,
        email: customer.email,
        street_address: customer.street_address,
        zip_code: customer.zip_code,
        city: customer.city
      })
      this.isOpen = false
    }
  }
} 
</script>

<template>
<ValidationObserver ref="observer">
    <form
      @submit.prevent
    >
      <slot></slot>
    </form>
  </ValidationObserver>
</template>
<script>
import { required, email, min, confirmed, integer, numeric } from 'vee-validate/dist/rules'
import { extend, ValidationObserver } from 'vee-validate'

/*setInteractionMode('passive')*/

extend('required', {
  ...required,
  message: 'Kenttä ei voi olla tyhjä',
})

extend('numeric', {
  ...numeric,
  message: 'Tulee olla numero',
})

extend('min', {
  ...min,
  message: 'Kentän tulee sisältää vähintään {length} merkkiä',
})

extend('email', {
  ...email,
  message: 'Sähköposti ei ole kelvollinen',
})

extend('confirmed', {
  ...confirmed,
  message: 'Salasanat eivät vastaa toisiaan',
})

extend('integer', {
  ...integer,
  message: 'Tulee olla kokonaisluku',
})

extend('required_if_not', {
  params: ['target'],
  validate(value, { target }) {
    if (
      (value === null || value === '')
      && (target === null || target === '')
    ) {
      return false
    } else {
      return true
    }  
  },
  message: 'Sähköposti tai puhelin vaaditan'
});



export default {
  name: 'BaseValidationForm',
  components: {
    ValidationObserver
  },
  props: {
    observerRef: {
      type: String,
      default: 'observer'
    }
  },
  methods: {
    async validate () {
      const result = await this.$refs['observer'].validate()
      return result
    }
  }
}
</script>



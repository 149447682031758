import Home from '../views/Home.vue'
import LoginView from '../views/LoginView.vue'
import ExtinguisherEditView from '../views/extinguisher/ExtinguisherEditView'
import ExtinguisherEditDataView from '../views/extinguisher/ExtinguisherEditDataView'
import ExtinguisherEditMaintenancesView from '../views/extinguisher/ExtinguisherEditMaintenancesView'
import ExtinguisherCreateView from '../views/extinguisher/ExtinguisherCreateView'
//import MaintenanceCreateView from '../views/maintenance/MaintenanceCreateView'
import MaintenanceEditView from '../views/maintenance/MaintenanceEditView'
import WorkOrderView from '@/views/WorkOrderView'
import WorkOrderPrint from '@/views/WorkOrderPrint'

export default [
	{
		path: '/kirjaudu',
		name: 'login',
		component: LoginView,
		meta: {
			requiresAuth: false
		}
	},
  {
    path: '/',
    name: 'home',
    component: Home,
		meta: {
			requiresAuth: true
		}
  },
	{
		path: '/sammutin/muokkaa/:id',
		name: 'extinguisher-edit',
		component: ExtinguisherEditView,
		redirect: '/sammutin/muokkaa/:id/tiedot',
		meta: {
			title: 'Muokkaa sammutinta',
			requiresAuth: true
		},
		children: [
			{
				path: 'tiedot',
				name: 'extinguisher-edit-data',
				component: ExtinguisherEditDataView,
				meta: {
					title: 'Sammuttin',
					requiresAuth: true
				}
			},
			{
				path: 'huollot',
				name: 'extinguisher-edit-maintenances',
				component: ExtinguisherEditMaintenancesView,
				meta: {
					title: 'Huoltohistoria',
					requiresAuth: true
				}
			}
		]
	},
	{
		path: '/sammutin/luo',
		name: 'extinguisher-create',
		component: ExtinguisherCreateView,
		meta: {
			title: 'Lisää uusi sammutin',
			requiresAuth: true
		}
	},
	//{
		//path: '/huolto/:extinguisher_id/luo',
		//name: 'maintenance-create',
		//component: MaintenanceCreateView,
		//meta: {
			//title: 'Lisää uusi huolto',
			//requiresAuth: true
		//}
	//},
	{
		path: '/huolto/:extinguisher_id/muokkaa/:maintenance_id',
		name: 'maintenance-edit',
		component: MaintenanceEditView,
		meta: {
			title: 'Muokkaa huoltoa',
			requiresAuth: true
		}
	},
	{
		path: '/tyomaarays',
		name: 'work-order',
		component: WorkOrderView,
		meta: {
			title: 'Luo työmääräys',
			requiresAuth: true
		}
	},
	{
		path: '/tyomaarays-tulostus',
		name: 'work-order-print',
		component: WorkOrderPrint,
		meta: {
			title: 'Tulosta työmääräys',
			requiresAuth: true
		}
	}
]

import gql from 'graphql-tag';

const notificationQuery = gql`
	query notification {
		notification @client {
			text
			type
			show
		}
	}
`;

const setNotification = gql`
  mutation(
		$text: String 
		$type: String
		$show: Boolean
	) {
		setNotification(
			text: $text
			type: $type
			show: $show
		) @client 	
	}`;

const notificationMutations = {
	setNotification (_, notification, { cache }) {
		const data = cache.readQuery({ query: notificationQuery });
		data.notification = {...data.notification, ...notification} 
		cache.writeQuery({ query: notificationQuery, data });
		return data.notification
	}
}

export {notificationMutations, setNotification, notificationQuery}

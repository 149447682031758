const storage = window.localStorage

export default {
  /**
   * @param {String} key
   */
  getItem (key) {
    return JSON.parse(storage.getItem(key))
  },

  /** 
   * @param {String} key
   */
  setItem (key, value) {
		let data = {}
		if (this.hasItem('slnshapp')) {
			data = this.getItem('slnshapp')
		}
		storage.setItem('slnshapp',  JSON.stringify({
			...data,
			[key]: value
		})) 
  },

  /**
   *
   * @param {String} key
   * @return {Boolean} 
   */
  hasItem (key) {
    return storage.getItem(key) !== null
  }
}



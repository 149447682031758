import gql from 'graphql-tag';

export const typeDefs = gql`
  type Item {
    id: ID!
    text: String!
    done: Boolean!
  }

	type Notification {
		text: String
		type: String
		show: Boolean
	}

	type WorkOrder {
		extinguishers: [Extinguisher]
		customer: Customer
	}

	input CustomerInput {
		id: String
		first_name: String
		last_name: String
		email: String
		phone: String
		street_address: String
		zip_code: String
		city: String
	}

	input ExtinguisherInput {
		id: String
		model: String
		weight: Float 
		serial_number: String
		price: Float
		maintenance_types: String
		qr_code: String
		customer_id: String
	}

	input Customer {
		id: String
		first_name: String
		last_name: String
		email: String
		phone: String
		street_address: String
		zip_code: String
		city: String
	}

	input WorkOrderInput {
		received: String
		returnDate: String
	}

  type Mutation {
		setNotification(text: String type: String show: Boolean): Notification
    checkItem(id: ID!): Boolean
    addItem(text: String!): Item
		toggleLoader(showLoader: Boolean): Boolean
		#Work order
		editWorkOrder(workOrder: WorkOrderInput): WorkOrder
		setCustomer(customer: CustomerInput): Customer
		addExtinguisher(extinguisher: ExtinguisherInput): Extinguisher 
		removeExtinguisher(id: ID!): Extinguisher 
  }
`;

<template>
  <div>
    <base-back-link @click="goToWorkOrderEdit">Työmääräyksen muokkaus</base-back-link>
    <button 
      class="btn"
      @click="print"
    >Tulosta</button>
    <div class="print-wrapper">
      <div id="work-order-print">
        <div class="print-header">
          <div class="print-logo">
            <img src="@/assets/slnshlogo.png" alt=""/>
          </div>
          <span class="print-header-text">TYÖTILAUS</span>
        </div>
        <div class="print-contact">
          <div>Yhdystie 46, 57210 Savonlinna</div>
          <div>Puh. 015 522 227</div>
          <div>petri.aalto@slnsh.fi</div>
          <div>www.slnsh.fi</div>
          <div class="print-open-hours">
            Aukioloajat: ma-pe 8-16
          </div>
        </div>
        <div class="print-body">
          <div class="print-dates print-section">
            <div class="print-date">
              <div class="print-title">
                Otettu vastaan
              </div>
              <div class="print-content">
                {{received}}
              </div>
            </div>
            <div class="print-date print-date--return">
              <div class="print-title">
                Luovutettavissa
              </div>
              <div class="print-content">
                {{returnDate}}
              </div>
            </div>
          </div>
          <div class="print-owner print-section">
            <div class="print-title">Omistajan tiedot</div>
            <div class="print-content">{{customerName}}</div>
            <div class="print-content">Puhelin: {{workOrder.customer.phone}}</div>
            <div class="print-content">Sähköposti: {{workOrder.customer.email}}</div>
          </div>
          <div class="print-extinguishers print-section">
            <div class="print-title">Sammuttimet</div>
            <div 
              v-for="extinguisher in workOrder.extinguishers"
              :key="extinguisher.id"
              class="print-extinguisher"
            >
               <div class="extinguisher-row model">
                 <span class="upper-span">Malli:</span>
                 <span> {{extinguisher.model}}</span>
              </div>
              <div class="extinguisher-row seriel">
                 <span class="upper-span">Sarjanumero:</span>
                 <span> {{extinguisher.serial_number}}</span>
              </div>
              <div class="extinguisher-row interval">
                 <span class="upper-span">Huoltoväli:</span>
                 <span> {{extinguisher.inspection_interval}}</span>
              </div>
              <div class="extinguisher-row tasks">
                 <span class="upper-span">Tehtävät toimenpiteet:</span>
                 <span v-html="extinguisherMaintenanceTypes(extinguisher)"></span>
              </div>
              <div class="extinguisher-row price">
                 <span class="upper-span">Toimenpiteiden hinta:</span>
                 <span>
                   {{extinguisher.price}} €
                 </span>
              </div>
            </div>
            <div class="print-title-section">
              <div class="print-title">Arvioitu yhteishinta: {{totalPrice}} € (sis. ALV)</div>
            </div>
          </div>
        </div>

        <div>
          <span><hr class="hr"/></span>
        </div>

        <div class="print-body">
          <div class="print-dates print-section">
            <div class="print-date">
              <div class="print-title">
                Otettu vastaan
              </div>
              <div class="print-content">
                {{received}}
              </div>
            </div>
            <div class="print-date print-date--return">
              <div class="print-title">
                Luovutettavissa
              </div>
              <div class="print-content">
                {{returnDate}}
              </div>
            </div>
          </div>
          <div class="print-owner print-section">
            <div class="print-title">Omistajan tiedot</div>
            <div class="print-content">{{customerName}}</div>
            <div class="print-content">Puhelin: {{workOrder.customer.phone}}</div>
            <div class="print-content">Sähköposti: {{workOrder.customer.email}}</div>
          </div>
          <div class="print-extinguishers print-section">
            <div class="print-title">Sammuttimet</div>
            <div 
              v-for="extinguisher in workOrder.extinguishers"
              :key="extinguisher.id"
              class="print-extinguisher"
            >
               <div class="extinguisher-row model">
                 <span class="upper-span">Malli:</span>
                 <span> {{extinguisher.model}}</span>
              </div>
              <div class="extinguisher-row seriel">
                 <span class="upper-span">Sarjanumero:</span>
                 <span> {{extinguisher.serial_number}}</span>
              </div>
              <div class="extinguisher-row interval">
                 <span class="upper-span">Huoltoväli:</span>
                 <span> {{extinguisher.inspection_interval}}</span>
              </div>
              <div class="extinguisher-row tasks">
                 <span class="upper-span">Tehtävät toimenpiteet:</span>
                 <span v-html="extinguisherMaintenanceTypes(extinguisher)"></span>
              </div>
              <div class="extinguisher-row price">
                 <span class="upper-span">Toimenpiteiden hinta:</span>
                 <span>
                   {{extinguisher.price}} €
                 </span>
              </div>
            </div>
            <div class="print-title-section">
              <div class="print-title">Arvioitu yhteishinta: {{totalPrice}} € (sis. ALV)</div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import { workOrderQuery} from '@/graphql/local/queries/work-order'
import printJS from 'print-js'
import {timeMixins} from '@/mixins/time-mixins'

export default {
  name: 'WorkOrderPrint',
  mixins: [timeMixins],
  apollo: {
    workOrder: {
      query: workOrderQuery 
    },
  },
  computed: {
    customerName () {
      return `${this.workOrder.customer.first_name} ${this.workOrder.customer.last_name}`
    },
    received () {
      return this.formatDay(this.workOrder.received)
    },
    returnDate () {
      return this.formatDay(this.workOrder.returnDate)
    },
    totalPrice () {
      let totalPrice = 0
      this.workOrder.extinguishers.forEach(extinguisher => {
        totalPrice = totalPrice + parseFloat(extinguisher.price)
      })
      return totalPrice.toFixed(2)
    },
    isMobile(){
      if(window.innerWidth < 550){
        return true;
      }
      else{
        return false
      }
    }
  },
  methods: {
    goToWorkOrderEdit () {
      this.$router.replace({path: 'tyomaarays'})
    },
    extinguisherMaintenanceTypes (extinguisher) {
      if (extinguisher.maintenanceTypes === null || extinguisher.maintenanceTypes === '') {
        return ''
      } else {
        let typesString = ''
        const types = JSON.parse(extinguisher.maintenance_types)
        if (types === null) {
          return ''
        }
        types.forEach((type, index) => {
          const typeName = ((maintenanceType) => {
            switch(maintenanceType) {
              case 'fill':
                return 'Täyttö'
              case 'maintenance':
                return 'Huolto'
              case 'repair':
                return 'Korjaus'
              default:
                return 'Tarkastus'
            } 
          })(type)  
          const comma = index === 0
            ? ''
            : ', '
          typesString = ` ${typesString}${comma}${typeName}`
        })
        return typesString
      }
    },
    print () {
      printJS({
        printable: 'work-order-print',
        type: 'html',
        css: `${window.location.origin}/printstyles.css`
        })
    },
  }
}
</script>
<style>
@import '../../public/printstyles.css';

.print-wrapper {
  margin-top: 20px;
  border: 1px solid gray;
}

</style>

<template>
  <div class="mb-4 flex justify-center">
    <div class="tab-buttons flex relative">
      <div 
        v-for="(tab, index) in tabs"
        :key="index"
        class="text-gray-700 py-4 px-6 block relative cursor-pointer"
        @click="changeTab(tab, index)"
      >
        <span v-if="useRoutes">{{tab.meta.title}}</span>
        <span v-else>{{tab}}</span>
      </div>
      <div 
        class="bg-red-700 w-4 h-1 absolute bottom-0 left-0 transition-all duration-200 linear"
        :style="{
        width: sliderWidth + 'px',
        left: left + 'px'
        }"
      >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BaseTabs',
  props: {
    tabs: {
      type: Array,
      required: true
    },
    useRoutes: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      sliderWidth: 10,
      positions: [],
      left: 0
    }
  }, 
  mounted () {
    //TODO needs id selector if there is more than one base-tabs component
    const tabs = document.querySelector('.tab-buttons').children
    tabs.forEach(tab => {
      this.positions.push(tab.offsetWidth)
    })
    this.sliderWidth = this.positions[0]
    if (this.useRoutes) {
      const routeName =  this.$route.name
      const tabIndex = this.tabs.findIndex(tab => tab.name === routeName)
      if (tabIndex !== -1) {
        this.changeTabMarker(tabIndex)
        /*this.$emit('changeTab', this.tabs[tabIndex])*/
      }
    }
  },
  watch: {
    /**
     * If using route tabs, change tab marker only after route change.
     * This way won't init the change if the there is a page leave confirmation
     */
    $route(to) {
      if (this.useRoutes) {
        const index = this.tabs.findIndex(tab => {
          return tab.name === to.name
        })
        if (index !== -1) {
          this.changeTabMarker(index)   
        }
      }
    }
  },
  methods: {
    changeTab (tab, index) {
      if (!this.useRoutes) {
        this.changeTabMarker(index)
      }
      this.$emit('changeTab', tab)
    },
    changeTabMarker (index) {
      this.sliderWidth = this.positions[index]
      let position = 0
      if (index !== 0) {
        for (let i = 0; i < index; i++) {
          position = position + this.positions[i]
        }
      }
      this.left = position
    }
  }
}
</script>


<template>
  <div>
    <div class="mb-3">
      <button 
        class="btn"
        @click="searchOpen = true"
      >Lisää/Vaihda omistaja</button>
      <button 
        class="btn btn-secondary ml-2"
        @click="customerCreatorOpen = true"
      >Luo uusi asiakas</button>
      <button 
        v-if="hasBeenAdded"
        class="btn btn-secondary ml-2"
        @click="editDisabled = false"
      >Muokkaa omistajan tietoja</button>
    </div>
    <customer-search
      v-model="searchOpen"
      @chooseCustomer="chooseCustomer"
    ></customer-search>
    <div v-if="hasBeenAdded">
      <customer-form
        v-model="formData"
        :editDisabled="editDisabled"
      ></customer-form>
      <button 
        v-if="!editDisabled && showUpdateButton"
        class="btn btn-tertiary"
        @click="$emit('update')"
      >Tallenna omistajan muutokset</button>
    </div>
    <div
      v-else
      class="text-red-600"
    >
      Omistajaa ei ole asetettu
    </div>
    <customer-creator
      v-model="customerCreatorOpen"
    ></customer-creator>
  </div>
</template>
<script>
import CustomerCreator from '@/components/CustomerCreator'
import CustomerSearch from '@/components/CustomerSearch'
import CustomerForm from '@/components/CustomerForm'

export default {
  name: 'CustomerData',
  components: {
    CustomerSearch,
    CustomerForm,
    CustomerCreator
  },
  props: {
    value: Object,
    hasBeenAdded: {
      type: Boolean,
      default: false
    },
    showUpdateButton: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      customerCreatorOpen: false,
      searchOpen: false,
      editDisabled: true
    }
  },
  computed: {
    formData: {
      get () {
        return this.value
      },
      set (formData) {
        this.edit(formData)
      } 
    }
  },
  methods: {
    edit (payload) {
      this.$emit('input', payload)
    },
    chooseCustomer (customer) {
      this.edit(customer) 
    }
  }
}
</script>

import gql from 'graphql-tag';
import Vue from 'vue'

const workOrderQuery = gql`
	query workOrder {
		workOrder @client {
			received
			returnDate
			customer {
				id
				first_name
				last_name
				email
				phone
				street_address
				zip_code
				city
			}
			extinguishers {
				id
				serial_number
				weight
				inspection_interval
				model
				model_year
				qr_code
				maintenance_types
				customer_id
				isNew @client
				price @client
			}
		}
	}
`;

const editWorkOrder = gql`
  mutation($workOrder: String!) {
		editWorkOrder(workOrder: $workOrder) @client
	}`

const setCustomer = gql`
  mutation($customer: CustomerInput!) {
		setCustomer(customer: $customer) @client
	}`

const addExtinguisher = gql`
  mutation($extinguisher: ExtinguisherInput!) {
		addExtinguisher(extinguisher: $extinguisher) @client
	}`

const removeExtinguisher = gql`
  mutation($id: ID!) {
		removeExtinguisher(id: $id) @client
	}`

const editExtinguisher = gql`
  mutation($extinguisher: ExtinguisherInput!) {
		editExtinguisher(extinguisher: $extinguisher) @client
	}`

const workOrderMutations = {
	editWorkOrder (_, payload, { cache }) {
		const data = cache.readQuery({ query: workOrderQuery });
		data.workOrder = {...data.workOrder, ...payload.workOrder} 
		cache.writeQuery({ query: workOrderQuery, data });
		return data.workOrder
	},
	setCustomer (_, customerData, { cache }) {
		const data = cache.readQuery({ query: workOrderQuery });
		data.workOrder.customer = {
			...customerData.customer, 
			__typename: 'Customer',
		} 
		cache.writeQuery({ query: workOrderQuery, data });
		return data.workOrder.customer
	},
	addExtinguisher (_, extinguisherData, { cache }) {
		const data = cache.readQuery({ query: workOrderQuery });
		data.workOrder.extinguishers = data.workOrder.extinguishers.concat(extinguisherData.extinguisher)
		cache.writeQuery({ query: workOrderQuery, data });
		return data.workOrder.extinguishers
	},
	removeExtinguisher (_, {id}, { cache }) {
		const data = cache.readQuery({ query: workOrderQuery });
		const index = data.workOrder.extinguishers.findIndex(extinguisher => extinguisher.id === id)
		data.workOrder.extinguishers.splice(index, 1)
		cache.writeQuery({ query: workOrderQuery, data });
		return data.workOrder.extinguishers
	},
	editExtinguisher (_, extinguisherData, { cache }) {
		const data = cache.readQuery({ query: workOrderQuery });
		const extinguisherIndex = data.workOrder.extinguishers.findIndex(extinguisher => {
			return extinguisher.id === extinguisherData.extinguisher.id 
		})
		Vue.set(
			data.workOrder.extinguishers,
			extinguisherIndex,
			{
				...extinguisherData.extinguisher,
				__typename: 'Extinguisher'
			}
		)		
		cache.writeQuery({ query: workOrderQuery, data });
		return data.workOrder.extinguishers
	},
}

export {workOrderMutations, editWorkOrder, setCustomer, workOrderQuery, addExtinguisher, removeExtinguisher, editExtinguisher}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"flex flex-wrap"},[_c('div',{staticClass:"w-full sm:w-1/2 sm:pr-1"},[_c('ValidationProvider',{attrs:{"name":"extinguisher_model","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('base-input-field',{attrs:{"label":'Sammuttimen malli',"errors":errors},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})]}}])})],1),_c('div',{staticClass:"w-full sm:w-1/2 sm:pl-1"},[_c('ValidationProvider',{attrs:{"name":"extinguisher_serial_number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('base-input-field',{attrs:{"label":'Sammuttimen sarjanumero',"errors":errors},model:{value:(_vm.serial_number),callback:function ($$v) {_vm.serial_number=$$v},expression:"serial_number"}})]}}])})],1)]),_c('div',{staticClass:"flex flex-wrap"},[_c('div',{staticClass:"w-full sm:w-1/2 sm:pr-1"},[_c('ValidationProvider',{attrs:{"name":"extinguisher_weight","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('base-input-field',{attrs:{"label":'Sammuttimen paino',"errors":errors,"inputType":'number'},model:{value:(_vm.weight),callback:function ($$v) {_vm.weight=$$v},expression:"weight"}})]}}])})],1),_c('div',{staticClass:"w-full sm:w-1/2 sm:pl-1"},[_c('ValidationProvider',{attrs:{"name":"extinguisher_year","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('base-input-field',{attrs:{"label":'Sammuttimen vuosimalli',"errors":errors,"inputType":'number'},model:{value:(_vm.model_year),callback:function ($$v) {_vm.model_year=$$v},expression:"model_year"}})]}}])})],1)]),_vm._m(0),_c('inspection-interval',{attrs:{"chosen":_vm.value.inspection_interval},on:{"toggleChip":_vm.changeInspectionInterval}}),(_vm.qr_code)?_c('div',[_c('div',{staticClass:"mt-4 mb-4 text-green-600"},[_vm._v(" QR-koodi lisätty (Koodi: "+_vm._s(_vm.qr_code)+") ")]),_c('button',{staticClass:"btn btn-tertiary",on:{"click":function($event){_vm.qrReaderOpen = true}}},[_vm._v("Vaihda QR-koodi")])]):_c('div',[_c('div',{staticClass:"mt-4 mb-4 text-red-600"},[_vm._v("QR-koodi puuttuu")]),_c('button',{staticClass:"btn btn-tertiary",on:{"click":function($event){_vm.qrReaderOpen = true}}},[_vm._v("Lisää QR-koodi")])]),(_vm.qrReaderOpen)?_c('qr-reader',{attrs:{"operationType":'scan'},on:{"close":function($event){_vm.qrReaderOpen = false},"getCode":_vm.addCode}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full"},[_c('label',{staticClass:"block text-sm font-medium leading-5 text-gray-700 mb-1 mt-2"},[_vm._v(" Tarkastusväli ")])])}]

export { render, staticRenderFns }
<template>
  <div v-if="$apollo.queries.extinguisher.loading">Ladataan...</div>
  <div v-else-if="$apollo.queries.extinguisher.error">Virhe tietojen haussa</div>
  <div 
    v-else
    :class="[fadeIn]"
  >
    <div class="mb-4">
      <div
        v-if="extinguisher.maintained"
      >Sammuttimen tila: <span class="text-green-600">Huollettu</span></div>
      <div
        v-else
      >Sammuttimen tila: <span class="text-orange-600">Huollossa</span></div>
    </div>
    <button 
      v-if="extinguisher.maintained"
      class="btn mb-4"
      @click="addToBeMaintained"
    >Aseta huollettavaksi</button>

    <base-validation-form ref="maintenanceCompleteForm">
      <maintenance-form
        v-if="!extinguisher.maintained"
        v-model="maintenanceData"
        :create="true"
        @save="completeMaintenance"
      ></maintenance-form>
    </base-validation-form>
    <extinguisher-form
      v-model="extinguisher"
      :maintenance="maintenance"
      @editMaintenance="editMaintenance"
      @save="updateExtinguisher"
      ref="extinguisherEditForm"
    ></extinguisher-form>
  </div>
</template>
<script>
import ExtinguisherForm from '@/components/ExtinguisherForm'
import MaintenanceForm from '@/components/MaintenanceForm'
import animationMixins from '@/mixins/animation-mixins'
import getExtinguisher from '@/graphql/queries/Extinguisher.gql'
/*import getMaintenances from '@/graphql/queries/Maintenances.gql'*/
import updateExtinguisher from '@/graphql/mutations/UpdateExtinguisher.gql'
import addToBeMaintained from '@/graphql/mutations/AddToBeMaintained.gql'
import completeMaintenance from '@/graphql/mutations/CompleteMaintenance.gql'
import { stateMixins } from '@/mixins/state-mixins'
import { timeMixins } from '@/mixins/time-mixins'
import { commonMixins } from '@/mixins/common-mixins'
import _ from 'lodash'

export default {
  name: 'ExtinguisherEditDataView',
  components: {
    MaintenanceForm,
    ExtinguisherForm
  },
  mixins: [animationMixins, stateMixins, timeMixins, commonMixins],
  data () {
    return {
      extinguisher: {},
      maintenance: {
        received: null,
        date_completed: null,
        maintainer_id: null,
        types: JSON.stringify([]),
        extinguisher_id: this.$route.params.id
      }
    }
  },
  mounted () {
    this.animationDone = true
  },
  apollo: {
    extinguisher: {
      query: getExtinguisher,
      variables () {
        return {
          id: this.$route.params.id
        }
      },
      error (error) {
        console.log(error.message)
      },
      result ({ data: {extinguisher} }) {
        const editableExtinguisher = _.cloneDeep(extinguisher) 
        /*delete editableExtinguisher.__typename*/
        /*delete editableExtinguisher.owner.__typename*/
        this.extinguisher = editableExtinguisher
        if (!extinguisher.maintained) {
          this.maintenance = {
            received: extinguisher.received,
            date_completed: null,
            maintainer_id: null,
            types: extinguisher.maintenance_types,
            extinguisher_id: extinguisher.id
          }
        }
      }
    } 
  },
  computed: {
    maintenanceData: {
      get () {
        return this.maintenance
      },
      set (maintenanceData) {
        this.editMaintenance(maintenanceData)
      } 
    }
  },
  methods: {
    async addToBeMaintained () {
      this.toggleLoader(true)
      try {
        this.$apollo.mutate({
          mutation: addToBeMaintained,
          variables: {
            id: this.extinguisher.id,
            received: this.toDate(Date.now()),
            maintained: false
          },
            update: (store, { data: { addToBeMaintained } }) => {
              const data = store.readQuery({ 
                query: getExtinguisher,
                variables: {id: this.$route.params.id}
              })
              data.extinguisher = {
                ...data.extinguihser,
                ...addToBeMaintained
              }
              // Write our data back to the cache.
              store.writeQuery({ query: getExtinguisher, data })
              this.maintenance = {
                received: null,
                date_completed: null,
                maintainer_id: null,
                types: JSON.stringify([]),
                extinguisher_id: this.$route.params.id
              }
            },
        }) 
        this.setNotification({
          text: 'Sammutin asetettiin huollettavaksi',
          type: 'success',
        })
        /*this.$apollo.queries.extinguisher.refetch()*/
        this.maintenance = null
      } catch (e) {
        this.setNotification({
          text: 'Epäonnistui',
          type: 'error',
        })
      } finally {
        this.toggleLoader(false)
      }
    },
    editMaintenance (payload) {
      this.maintenance = {
        ...this.maintenance,
        ...payload
      }
    },
    async updateExtinguisher () {
      const valid = await this.$refs['extinguisherEditForm'].validate()
      if (
        Object.prototype.hasOwnProperty.call(this.extinguisher, 'owner')
        && this.extinguisher.owner !== null
        && (this.extinguisher.owner.phone === null || this.extinguisher.owner.phone === '')
        && (this.extinguisher.owner.email === null || this.extinguisher.owner.email === '')
      ) {
        this.setNotification({
          text: 'Omistajan sähköposti tai puhelinnumero tulee olla syötetty',
          type: 'warning',
        })
        return
      } 
      if (!valid) {
        this.setNotification({
          text: 'Tarkista kentät',
          type: 'warning',
        })
      } else {
        this.toggleLoader(true)
        try {
          let extinguisherData = _.cloneDeep(this.extinguisher)
          if (Object.prototype.hasOwnProperty.call(extinguisherData, '__typename')) {
            delete extinguisherData['__typename']
          }
          if (
            Object.prototype.hasOwnProperty.call(extinguisherData, 'owner')
            && extinguisherData.owner !== null
            && Object.prototype.hasOwnProperty.call(extinguisherData.owner, '__typename')
          ) {
            delete extinguisherData.owner['__typename']
          }
          await this.$apollo.mutate({
            mutation: updateExtinguisher,
            variables: {
              ...extinguisherData
            },
              update: (store, { data: { updateExtinguisher } }) => {
                // Read the data from our cache for this query.
                const data = store.readQuery({ 
                  query: getExtinguisher,
                  variables: {id: this.$route.params.id}
                })
                data.extinguisher = updateExtinguisher
                // Write our data back to the cache.
                store.writeQuery({ query: getExtinguisher, data })
              },
          }) 
          this.setNotification({
            text: 'Sammuttimen tiedot tallennettiin',
            type: 'success',
          })
          /*this.$apollo.queries.extinguisher.refetch()*/
        } catch (e) {
          const notificationError = this.getValidationErrors(e)
          this.setNotification({
            text: `Epäonnistui: ${notificationError}`,
            type: 'error',
          })
        } finally {
          this.toggleLoader(false)
        }
      }
    },
    async completeMaintenance () {
      const valid = await this.$refs['maintenanceCompleteForm'].validate()
      if (!valid) {
        this.setNotification({
          text: 'Tarkista kentät',
          type: 'warning',
        })
      } else {
        this.toggleLoader(true)
        try {
          await this.$apollo.mutate({
            mutation: completeMaintenance,
            variables: {
              id: this.extinguisher.id,
              maintained: true,
              maintenance_types: null,
              received: null, 
              maintenance: this.maintenance 
            },
            update: (store) => {
              let data = store.readQuery({ 
                query: getExtinguisher,
                variables: {id: this.$route.params.id}
              })
              data.extinguisher = {
                ...data.extinguisher,
                received: null,
                maintenance_types: null,
                maintained: true
              }
              store.writeQuery({ query: getExtinguisher, data })
            },
          }) 
          /*this.$apollo.queries.extinguisher.refetch()*/
          this.maintenance = null
          this.setNotification({
            text: 'Huolto lisättiin sammuttimelle',
            type: 'success',
          })
        } catch (e) {
          this.setNotification({
            text: `Epäonnistui (${e.message})`,
            type: 'error',
          })
        } finally {
          this.toggleLoader(false)
        }
      }
    }
  }
}
</script>

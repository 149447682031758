<template>
  <div 
    class="w-full rounded overflow-hidden shadow-lg cursor-pointer"
    :class="[fadeInUpFaster]"
    @click="$emit('edit', cardData.id)"
  >
    <div class="flex flex-col md:flex-row items-stretch h-full px-6 py-6">
      <div 
        class="inline-flex flex-col"
        :class="[
        `${breakpoint}:flex-row`, 
        `${breakpoint}:items-center`,
        {'w-full': !cardData.hasChooseButton},
        {'md:w-7/8': cardData.hasChooseButton}
        ]"
      >
      <div 
        v-if="cardData.hasOwnProperty('title')"
        class="text-gray-700 text-base text-xl"
        :class="[singleDataWidth, `${cardData.title.color}`]"
      >{{cardData.title.text}}</div>
      <div 
        v-for="(item, index) in cardData.items"
        :key="index"
        class="inline-flex flex-row-reverse items-center justify-end h-full"
        :class="[singleDataWidth, `${breakpoint}:items-start`, `${breakpoint}:flex-col`, `${breakpoint}:justify-center`]"
      >
        <span>{{item.value}}</span>
        <span class="text-xs text-gray-700 mr-2">{{item.subtitle}}</span>
      </div>
    </div>
      <div 
        v-if="cardData.hasChooseButton"
        class="inline-flex md:flex-col flex-1 justify-center md:items-end justify-center mt-2 mb-3 w-full md:w-1/2"
      >
        <button 
          class="btn"
          @click="$emit('choose')"
        >Valitse</button>
      </div>
  </div>
</div>
</template>
<script>
import animationMixins from '@/mixins/animation-mixins'

export default {
  name: 'BaseListCard',
  props: {
    cardData: {
      type: Object,
      required: true
    },
    breakpoint: {
      type: String,
      default: 'md'
    }
  },
  mixins: [animationMixins],
  computed: {
    singleDataWidth () {
      const maxWidth = this.cardData && Object.prototype.hasOwnProperty.call(this.cardData, 'title')
        ? this.cardData.items.length + 1
        : this.cardData.items.length
      return `${this.breakpoint}:w-1/${maxWidth}`
    } 
  },
  /*
   * TODO Change to animate when list is done loading
   */
  mounted () {
    this.animationDone = true
  }
}
</script>
